//packages
import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

//helpers
import "./signIn.css";

export default class SignIn extends Component {
  state = {
    showPassword: false,
    showDropDown: true,
  };

  /*
        Shows and hides name dropdown based on user's action
    */

  handleCheckBox = (e) => {
    this.setState({
      showDropDown: e.target.checked,
    });
  };

  /*
        Shows and hides password based on user's action
    */

  handleShowPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {
      userName,
      email,
      handleInputChange,
      onSubmit,
      isOwner,
      password,
      adminNames,
    } = this.props;

    const { showDropDown, showPassword } = this.state;

    return (
      <Form onSubmit={onSubmit}>
        {!isOwner && (
          <Form.Group controlId="formGroupName">
            <Form.Label>
              Name
              <span className="mb-3 ml-2">
                <input
                  type="checkbox"
                  onChange={this.handleCheckBox}
                  checked={showDropDown}
                  id="name_field"
                />
                <label htmlFor="name_field" className="ml-2">
                  Select from dropdown
                </label>
              </span>
            </Form.Label>
            {!showDropDown ? (
              <Form.Control
                type="text"
                name="userName"
                value={userName}
                onChange={handleInputChange}
              />
            ) : (
              <Form.Control
                as="select"
                name="userName"
                value={userName}
                onChange={handleInputChange}
              >
                <option value="" disabled={true}>
                  Select
                </option>
                {adminNames.map((name, i) => (
                  <React.Fragment key={i}>
                    <option value={name}>{name}</option>
                  </React.Fragment>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        )}
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            autoComplete="new-password"
          />
        </Form.Group>
        {isOwner && (
          <Form.Group controlId="formGroupPassword">
            <Form.Label>
              Password{" "}
              <span className="ml-2" onClick={this.handleShowPassword}>
                {!showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </Form.Label>
            <Form.Control
              type={showPassword ? "type" : "password"}
              name="password"
              value={password}
              onChange={handleInputChange}
              autoComplete="new-password"
            />
          </Form.Group>
        )}

        <div className="text-center pt-3">
          <Button variant="primary" type="submit">
            Sign in
          </Button>
        </div>
      </Form>
    );
  }
}
