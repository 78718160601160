// packages
import React, { Component } from "react";
import ScheduleSelector from "react-schedule-selector";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";

//helpers
import WeeklyLayoutWrapper from "../../components/weeklyLayoutWrapper/weeklyLayoutWrapper";
import {
  Network,
  showAlert,
  getExpandedEntries,
  getFormattedEntries,
  getStartDateByWeek,
  getEndDateByWeek,
} from "../../utils/helpers";
import {
  STATUS_CODES,
  API_URL_V1,
  API_URL_V2,
  TIMEZONES,
} from "../../utils/constants";
import "../mainPage/mainpage.css";
import "./weeklyScheduler.css";
import TeamView from "./teamView";
import MeetingsView from "./newMeetings/newMeetings";
import Tasks from "./Tasks/tasks";

const DateCell = styled.div`
  width: 100%;
  height: 25px;
  background-color: ${(props) =>
    props.selected ? props.selectedColor : props.unselectedColor};
  &:hover {
    background-color: ${(props) => props.hoveredColor};
  }
`;
const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

class WeeklyScheduler extends Component {
  state = {
    userName: "",
    schedule: [],
    eventDetails: {},
    isLoading: true,
    scheduledTimeSlots: [],
    isScheduled: false,
    selectionScheme: "square",
    timezone: "IST",
    eventTimeZone: "IST",
    adminId: "",
    weekSelected: "current",
    updated_at: "",
    meetingsData: {},
    currentTab: "individual",
    teamDetails: [],
    admins: {},
    adminNames: {},
  };

  /*
        Gets event details from API to pass as paramaters to <ScheduleSelector>
    */
  //TODO split this function
  getEventDetails = async (weekSelected) => {
    if (!weekSelected) {
      showAlert("error", "Some error occured, try again or contact developer");
      return;
    }
    let startDate = getStartDateByWeek(weekSelected);

    const url = `${API_URL_V2}/scheduler/weekly/${startDate}`;
    const userName = localStorage.getItem("user_name");
    const adminId = this.auth_token.split("@")[0];
    try {
      const headers = {
        "auth-token": this.auth_token,
      };
      const res = await Network(url, "GET", null, headers);
      if (res.statusCode === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        showAlert(
          "error",
          "Some error occured, try again or contact developer"
        );
        this.props.history.push("/");
      } else if (res.statusCode === STATUS_CODES.SUCCESS) {
        let entries = res.data.user?.entries || [];

        const { event_name, start_date, timezone } = res.data;
        const start_time = 0;
        const end_time = 24;
        const time_interval = 2;
        if (!Array.isArray(entries)) {
          entries = getExpandedEntries(entries);
        }
        // Convert the time slots selected by the current user from string(dd-mm-yyy_HH:MM) to new Date()
        entries = entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          // MM - 1 because month is zero indexed, so for ex Jan is 0 and not 1
          return new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });

        // Convert the time slots where the meetings are scheduled for the current user from string(dd-mm-yyy_HH:MM) to new Date()
        let scheduledTimeSlots = res.data.scheduled_entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          return new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });

        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${String(start_time).padStart(2, "0")}:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${String(end_time).padStart(2, "0")}:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES[timezone])
            .format("ddd MMM DD YYYY")
        );
        let stateData = {
          isLoading: false,
          userName,
          eventTimeZone: timezone,
          adminId,
          updated_at: res.data.user?.updated_at || "",
          eventDetails: {
            eventName: event_name,
            numDays: 7,
            eventStartDt,
            eventEndDt,
            startDate,
            minTime: Number(start_time),
            maxTime: Number(end_time),
            timeInterval: Number(time_interval),
          },
          schedule: entries,
          scheduledTimeSlots,
          isScheduled: scheduledTimeSlots.length > 0,
        };

        this.setState({
          ...stateData,
        });
      } else if (res.statusCode === STATUS_CODES.NOT_FOUND) {
        let stateData = {
          isLoading: false,
          userName,
          adminId,
          updated_at: "",
          schedule: [],
        };
        let start_date = getStartDateByWeek(weekSelected);
        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} 00:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} 24:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES["IST"])
            .format("ddd MMM DD YYYY")
        );

        stateData.eventDetails = {
          eventName: "Weekly Availability",
          numDays: 7,
          eventStartDt,
          eventEndDt,
          startDate,
          minTime: 0,
          maxTime: 24,
          timeInterval: 2,
        };

        this.setState({
          ...stateData,
        });
      } else if (res.statusCode === STATUS_CODES.NOT_AUTHORIZED) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
        this.props.history.push("/");
      } else {
        showAlert("error", res.status);
        this.props.history.push("/");
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      showAlert("error", error);
      this.props.history.push("/");
    }
  };

  /*
    Get all admins data
  */

  getAllAdmins = async () => {
    let url = `${API_URL_V1}/manage_admin`;
    const headers = {
      Authorization: this.auth_token,
    };
    try {
      const res = await Network(url, "GET", null, headers);
      let admins = {};
      let adminNames = {};
      res.data.forEach((admin) => {
        if (admin.is_active) {
          admins[admin.admin_id] = `${admin["name"] || ""}_${
            admin["company_email"] || ""
          }`;
          adminNames[admin["company_email"]] = admin["name"];
        }
      });
      this.setState({
        admins,
        adminNames,
      });
    } catch (err) {
      showAlert("error", "Some error occured, reload page again");
    }
  };

  async componentDidMount() {
    this.auth_token = localStorage.getItem("auth_token");
    let is_tasks = localStorage.getItem("tasks");
    localStorage.removeItem("tasks");
    if (this.auth_token) {
      await this.getEventDetails(this.state.weekSelected);
      await this.getProjectDetails();
      await this.getAllAdmins();
      if (is_tasks === "1") {
        this.handleSwitchView("tasks");
      }
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentTab === "individual" &&
      prevState.currentTab !== "individual"
    ) {
      this.setState(
        {
          timezone: "IST",
          weekSelected: "current",
        },
        () => this.getEventDetails(this.state.weekSelected)
      );
    }
  }

  /*
        stores input field date in state
    */

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /*
    Get all projects/team belonging to Immigreat
  */

  getProjectDetails = async () => {
    try {
      let url = `${API_URL_V1}/data/projects`;
      const headers = {
        Authorization: this.auth_token,
      };
      const res = await Network(url, "GET", null, headers);
      this.setState({
        teamDetails: res.data,
      });
    } catch (err) {
      showAlert("error", err);
    }
  };

  /*
    Stores timeZone selected and converts the selected time slots(dates) according to timezone from 
    Previous option -> this.state.timezone ex: IST
    to 
    Current option -> e.target.value ex: PST
    Execution in steps IST -> CET
    Wed May 12 2021 09:00:00 GMT+0530 (India Standard Time) -> format("YYYY-MM-DD HH:mm")(To strip the GMT) -> 2021-05-12 09:00
    -> Time Zone conversion happens -> Wed May 12 2021 05:30:00 GMT+0200 ->format("ddd MMM DD YYYY HH:mm:ss")-> Wed May 12 2021 05:30:00 -> Wed May 12 2021 05:30:00 GMT+0530 (India Standard Time)(It is shown as IST because new Date() considers local system timezone, but the date object will be in the form of CET)
    Refer
    https://momentjs.com/timezone/
    https://momentjs.com/timezone/docs/
  */

  handleTimeZoneSelector = (e) => {
    let { eventDetails } = this.state;

    let convertedTime = momentTimeZone
      .tz(eventDetails.eventStartDt, TIMEZONES[this.state.eventTimeZone])
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("ddd MMM DD YYYY@HH:mm");
    let startDate = new Date(convertedTime.split("@")[0]);

    this.setState({
      eventDetails: {
        ...this.state.eventDetails,
        startDate,
      },
      schedule: this.state.schedule.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      scheduledTimeSlots: this.state.scheduledTimeSlots.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      timezone: e.target.value,
    });
  };
  /*
        This function will be controlled by react-schedule-selector 
        and stores the time slot selected by user in state
        @params {Array} - newSchedule = [All timeslots selected by users in standard new Date() format]
    */

  handleScheduleChange = (newSchedule) => {
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );

    if (this.state.schedule.length < newSchedule.length) {
      // converts current local system time to selected timezone's current time
      if (+currentDateInTz > +newSchedule[newSchedule.length - 1]) {
        showAlert("error", "Past time cannot be selected");
        return;
      }
    }
    newSchedule = newSchedule.filter((date) => currentDateInTz < date);
    if (this.state.isScheduled) {
      let convertedEntries = [];
      for (let date of this.state.scheduledTimeSlots) {
        if (currentDateInTz < date) {
          convertedEntries.push(+date);
        }
      }
      let convertedSchedule = newSchedule.map((date) => +date);
      newSchedule = [
        ...new Set([...convertedEntries, ...convertedSchedule]),
      ].map((date) => new Date(date));
    }
    this.setState({ schedule: newSchedule });
  };

  /*
        Calls API to save user entries from Scheduler
    */

  saveUserAvailablity = async (postData) => {
    try {
      const url = `${API_URL_V2}/scheduler/weekly/save`;
      const headers = {
        "auth-token": this.auth_token,
      };
      const res = await Network(url, "POST", postData, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully saved");
        this.setState({
          updated_at: res.data.updated_at,
        });
      } else if (res.statusCode === STATUS_CODES.NOT_AUTHORIZED) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
        this.props.history.push("/");
      } else {
        showAlert("error", res.status);
      }
    } catch (err) {
      showAlert(
        "error",
        "Some error ocurred, try again or contact administrator"
      );
    }
    this.setState({ isLoading: false });
  };

  /*
        This function will be called when submit button is clicked
        Validates the data and calls the API to save the entires
    */

  handleAvailabilitySubmit = async (e) => {
    e.preventDefault();
    const {
      schedule,
      isScheduled,
      eventTimeZone,
      scheduledTimeSlots,
      timezone,
      weekSelected,
    } = this.state;
    let filteredSchedule = [...schedule];
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    for (let date of schedule) {
      if (date < currentDateInTz) {
        showAlert("error", "Past time cannot be selected");
        return;
      }
    }
    if (isScheduled) {
      let convertedEntries = [];
      for (let date of scheduledTimeSlots) {
        if (currentDateInTz < date) {
          convertedEntries.push(+date);
        }
      }
      let convertedSchedule = filteredSchedule.map((date) => +date);
      filteredSchedule = [
        ...new Set([...convertedEntries, ...convertedSchedule]),
      ].map((date) => new Date(date));
    }
    //this.setState({ isLoading: true });
    let start_date = getStartDateByWeek(weekSelected);
    let end_date = getEndDateByWeek(weekSelected);

    let postData = {
      entries: getFormattedEntries(filteredSchedule, timezone, eventTimeZone),
      start_date,
      end_date,
    };

    // Dates will be converted from current timezone selected to timezone selected while creating event
    await this.saveUserAvailablity(postData);
  };

  /*
        Renders the blocks in Scheduler. Controlled by react-schedule-selector
        if the date is past date, shows the block as red
        if the event is scheduled, show tick mark in that particular block
        @params {Date} date
        @params {Boolean} selected
    */

  customDateCellRenderer = (date, selected, refSetter) => {
    let color = "rgba(162, 198, 248, 1)";
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (currentDateInTz > date) {
      color = "#ff0000";
    }
    let scheduled = false;
    let timeSlots = this.state.scheduledTimeSlots.map((date) => +date);
    if (timeSlots.indexOf(+date) !== -1) {
      scheduled = true;
    }
    return (
      <DateCell
        selected={selected}
        ref={refSetter}
        selectedColor={"rgba(89, 154, 242, 1)"}
        unselectedColor={color}
        hoveredColor={"#dbedff"}
        onClick={this.handleSlotClick}
      >
        <div className="d-flex justify-content-center">
          {scheduled ? "✅" : ""}
        </div>
      </DateCell>
    );
  };

  /*
    Renders the owner and user view based on toggle
  */

  handleSwitchView = async (key, data = {}) => {
    if (key === "individual") {
      await this.getEventDetails(this.state.weekSelected);
      this.setState({
        currentTab: key,
        meetingsData: {},
      });
    } else if (key === "team" && data.start) {
      this.setState(
        {
          currentTab: key,
        },
        () =>
          this.setState({
            meetingsData: data,
          })
      );
    } else {
      this.setState({
        currentTab: key,
        meetingsData: {},
      });
    }
  };

  /*
    Changes Last Updated at data from Team view
  */

  handleUpdatedAt = (value) => {
    this.setState({
      updated_at: value,
    });
  };

  /* 
    Handles current week and next week UI changes based on user's selection
  */

  handleWeekSelection = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        timezone: "IST",
        isLoading: true,
      },
      async () => {
        if (!this.auth_token) {
          showAlert("error", "Not authorized");
          this.props.history.push("/");
        }
        await this.getEventDetails(this.state.weekSelected);
      }
    );
  };

  render() {
    const {
      eventDetails,
      userName,
      selectionScheme,
      isLoading,
      schedule,
      timezone,
      weekSelected,
      updated_at,
      meetingsData,
      currentTab,
      teamDetails,
      admins,
      adminNames,
    } = this.state;
    let maxTime;
    if (eventDetails.maxTime) {
      if (eventDetails.minTime >= eventDetails.maxTime)
        maxTime = Math.floor(24 + eventDetails.maxTime);
      else maxTime = eventDetails.maxTime;
    }
    let timeString = "Data not available";
    if (updated_at) {
      timeString = momentTimeZone
        .tz(moment(updated_at).format("YYYY-MM-DD HH:mm"), "utc")
        .clone()
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm A");
    }

    return (
      <WeeklyLayoutWrapper>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <>
            <div>
              <div className="event-details-container">
                <div className="event-data">
                  <span className="event-header">Event Name : </span>{" "}
                  {eventDetails.eventName}
                </div>
                <p className="mt-2 event-data">
                  <span className="event-header">Last updated at : </span>{" "}
                  {timeString}
                </p>
              </div>
            </div>
            <div className="tabs-view">
              <Tabs
                id="controlled-tab-example"
                activeKey={currentTab}
                onSelect={(k) => this.handleSwitchView(k)}
                className="mb-3 content-tabs"
              >
                <Tab eventKey="individual" title="My View" className="pt-4">
                  <div>
                    <div className="helper-text-weekly">
                      Hi {userName.charAt(0).toUpperCase() + userName.slice(1)}{" "}
                      ! Click or Drag below to fill your availablity
                    </div>
                    <div className="schedule-selector">
                      <div className="dropdown-container-weekly">
                        <Form.Group
                          controlId="formGroupScheme"
                          className="selection-scheme mx-auto"
                        >
                          <Form.Label>Selection Scheme</Form.Label>
                          <Form.Control
                            as="select"
                            name="selectionScheme"
                            value={selectionScheme}
                            onChange={this.handleInputChange}
                          >
                            <option value="linear">Linear</option>
                            <option value="square">Square</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="formGroupScheme"
                          className="selection-scheme mx-auto"
                        >
                          <Form.Label>Timezone</Form.Label>
                          <Form.Control
                            as="select"
                            value={timezone}
                            onChange={(e) => this.handleTimeZoneSelector(e)}
                          >
                            {Object.keys(TIMEZONES).map((timezone, i) => (
                              <option value={timezone} key={i}>
                                {timezone}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          controlId="formGroupScheme"
                          className="selection-scheme mx-auto"
                        >
                          <Form.Label>Select week</Form.Label>
                          <Form.Control
                            as="select"
                            name="weekSelected"
                            value={weekSelected}
                            onChange={this.handleWeekSelection}
                          >
                            <option value="current">Current week</option>
                            <option value="next">Next week</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <ScheduleSelector
                        selection={schedule}
                        startDate={eventDetails.startDate}
                        numDays={eventDetails.numDays}
                        minTime={eventDetails.minTime}
                        maxTime={maxTime}
                        timeFormat="h:mm A"
                        dateFormat="MMM DD - ddd"
                        hourlyChunks={eventDetails.timeInterval}
                        onChange={this.handleScheduleChange}
                        renderDateCell={this.customDateCellRenderer}
                        selectionScheme={selectionScheme}
                      />

                      <div className="text-center py-5">
                        <Button
                          variant="primary"
                          type="button"
                          onClick={this.handleAvailabilitySubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="team" title="Team View" className="pt-4">
                  <TeamView
                    meetingsData={meetingsData}
                    handleUpdatedAt={this.handleUpdatedAt}
                    handleSwitchView={this.handleSwitchView}
                    currentTab={currentTab}
                    admins={admins}
                    teamDetails={teamDetails}
                    adminNames={adminNames}
                  />
                </Tab>
                <Tab eventKey="meetings" title="Meetings">
                  <MeetingsView
                    handleSwitchView={this.handleSwitchView}
                    currentTab={currentTab}
                    teamDetails={teamDetails}
                    admins={admins}
                    adminNames={adminNames}
                  />
                </Tab>
                <Tab eventKey="tasks" title="Tasks">
                  <Tasks
                    adminId={this.state.adminId}
                    auth_token={this.auth_token}
                    currentTab={currentTab}
                    admins={admins}
                    teamDetails={teamDetails}
                  />
                </Tab>
              </Tabs>
            </div>
          </>
        )}
      </WeeklyLayoutWrapper>
    );
  }
}

export default WeeklyScheduler;
