//packages
import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

//helpers
import logoImg from "../../assets/images/navbar-logo.png";
import "./weeklyLayoutWrapper.css";

class LayoutWrapper extends Component {
  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="md" className="navbar-container">
          <Navbar.Brand>
            <Link to="/" id="logo_dashboard_link">
              <img src={logoImg} alt="logo" className="logo-img" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100">
              <NavLink
                activeClassName="active-header"
                className="header-link"
                exact
                to={`/weekly`}
              >
                <div className="header-button">Scheduler</div>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <main className="w-100">
          <div className="main-content">{this.props.children}</div>
        </main>
      </div>
    );
  }
}

export default LayoutWrapper;
