//packages
import React, { Component } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ScheduleSelector from "react-schedule-selector";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Select from "react-select/creatable";
import { intersection } from "lodash";
import { CgNotes } from "react-icons/cg";
import { MdAddCircle, MdOutlineRemoveCircle } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//helpers
import { Network, showAlert, getStartDateByWeek } from "../../utils/helpers";
import {
  STATUS_CODES,
  API_URL_V2,
  TIMEZONES,
  emailRegex,
  start_date_current,
  end_date_current,
} from "../../utils/constants";
import "../AdminView/AdminView.css";
import "./teamView.css";

const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

const time_interval_in_mins = {
  1: 60,
  2: 30,
  3: 20,
  4: 15,
};

const days = [
  { label: "Monday", value: "MO" },
  { label: "Tuesday", value: "Tu" },
  { label: "Wednesday", value: "WE" },
  { label: "Thursday", value: "TH" },
  { label: "Friday", value: "FR" },
  { label: "Saturday", value: "SA" },
  { label: "Sunday", value: "SU" },
];

class TeamView extends Component {
  auth_token = "";

  state = {
    userName: "",
    adminId: "",
    schedule: [],
    eventDetails: {},
    isLoading: true,
    selectedDate: "",
    timezone: "IST",
    eventTimeZone: "IST",
    weekSelected: "current",
    currentScreen: 1,
    meetingDetails: {
      title: "",
      description: [
        {
          val: "",
        },
      ],
      formattedDescription: "",
      eligibleAttendees: [],
      selectedAttendees: [],
      availableAttendees: [],
      project: "",
      meetingFrequency: "0",
      meetingRepeatEvery: 1,
      meetingMonthlyOption: "on day",
      meetingRepeatOnDay: 1,
      meetingRepeatOnTheNth: "1",
      meetingRepeatOnTheDay: "SU",
      meetingRepeatOption: "DAILY",
      meetingSelectedDay: [],
      meetingEnd: "never",
      meetingEndOn: "",
      meetingEndAfter: 5,
    },
    isSpecialAdmin: false,
    isEligibleAdmin: false,
    userEntries: [],
    userSelected: "",
    teamSelected: "",
    teamData: {
      teamEntries: {},
      teamMembers: [],
    },
    attendeesOption: 1,
    isSelectAll: false,
    showNotesModal: false,
    todoItems: [],
    todoText: "",
    notesText: "",
    notes: [],
    showDetailsModal: false,
    agendaCount: 1,
    showNewReleaseInfoModal: false,
    selectedTodoIndex: null,
  };

  /*
        Gets Event details to render the UI
        Entries are formatted from {"name":[dates]} -> ["date":[names]] for UI rendering
    */

  getEventDetails = async (weekSelected) => {
    try {
      let userName = localStorage.getItem("user_name");
      const _startDate = getStartDateByWeek(weekSelected);
      let url = `${API_URL_V2}/scheduler/weekly/team/${_startDate}`;
      const headers = {
        "auth-token": this.auth_token,
      };
      this.setState({
        isLoading: true,
      });
      const res = await Network(url, "GET", null, headers);
      const adminId = this.auth_token.split("@")[0];
      if (res.statusCode === STATUS_CODES.INTERNAL_SERVER_ERROR) {
        showAlert(
          "error",
          "Some error occured, try again or contact developer"
        );
        window.location.href = "/";
      } else if (res.statusCode === STATUS_CODES.SUCCESS) {
        const {
          employees_entries,
          event_name,
          start_date,
          start_time = 0,
          end_time = 24,
          time_interval = 2,
          timezone,
          updated_at,
          is_special_admin,
        } = res.data;
        let all_entries = [];
        let userNames = {};
        let formatted_entries = {};
        for (let adminId in employees_entries) {
          all_entries.push(...employees_entries[adminId]["entries"]);
          if (employees_entries[adminId]["entries"].length > 0) {
            let key =
              employees_entries[adminId]["name"] +
              "_" +
              employees_entries[adminId]["email"];
            userNames[adminId] = key;
          }
        }
        all_entries = [...new Set(all_entries)];
        for (let entry of all_entries) {
          formatted_entries[entry] = [];
          for (let adminId in employees_entries) {
            if (employees_entries[adminId]["entries"].indexOf(entry) !== -1) {
              formatted_entries[entry].push(adminId);
            }
          }
        }
        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${
          String(start_time).length === 1 ? "0" + start_time : start_time
        }:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${String(end_time).length === 1 ? "0" + end_time : end_time}:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES[timezone])
            .format("ddd MMM DD YYYY")
        );
        this.props.handleUpdatedAt(updated_at);
        this.setState({
          isLoading: false,
          eventTimeZone: timezone,
          userName,
          adminId,
          isSpecialAdmin: true, //is_special_admin,
          isEligibleAdmin: is_special_admin,
          eventDetails: {
            eventName: event_name,
            numDays: 7,
            eventStartDt,
            eventEndDt,
            startDate,
            minTime: Number(start_time),
            maxTime: Number(end_time),
            timeInterval: Number(time_interval),
            totalNoOfUsers: Object.keys(userNames).length,
            userNames,
            entries: formatted_entries,
          },
        });
      } else if (res.statusCode === STATUS_CODES.NOT_FOUND) {
        let start_date = getStartDateByWeek(weekSelected);
        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} 00:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} 24:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES["IST"])
            .format("ddd MMM DD YYYY")
        );
        this.setState({
          isLoading: false,
          userName,
          adminId,
          eventDetails: {
            eventName: "Weekly Availability",
            numDays: 7,
            eventStartDt,
            eventEndDt,
            startDate,
            minTime: 0,
            maxTime: 24,
            timeInterval: 2,
            entries: {},
            totalNoOfUsers: 0,
            userNames: {},
          },
        });
      } else if (res.statusCode === STATUS_CODES.NOT_AUTHORIZED) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
      } else {
        showAlert("error", res.status);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      showAlert("error", err);
    }
  };

  /*
        Renders the UI of team view
    */

  async componentDidMount() {
    this.auth_token = localStorage.getItem("auth_token");
    if (this.auth_token) {
      // Shows new features modal after 5s of loading the page
      localStorage.removeItem("newChangesPopup");
      localStorage.removeItem("showedFeaturesModal");
      localStorage.removeItem("showedChangesPopup");
      const showedModal = localStorage.getItem("newReleasesPopup");
      if (showedModal !== "true") {
        setTimeout(
          () => this.setState({ showNewReleaseInfoModal: true }),
          5000
        );
        localStorage.setItem("newReleasesPopup", "true");
      }
    } else {
      showAlert("error", "Some error occured, try again!");
      window.location.href = "/";
    }
  }

  /* helps to redirect from meetings page to team page 
  and open the modal based on the time slot selected on the meetings page
  and also reloads the page when the tab is changed
  */

  async componentDidUpdate(prev) {
    if (this.props.currentTab === "team" && prev.currentTab !== "team") {
      this.setState(
        {
          timezone: "IST",
          weekSelected: "current",
        },
        async () => {
          if (
            Object.keys(prev.meetingsData).length === 0 &&
            Object.keys(this.props.meetingsData).length > 0 &&
            this.props.meetingsData.start &&
            this.props.meetingsData.end
          ) {
            const weekSelected = moment(
              this.props.meetingsData.start
            ).isBetween(
              moment(start_date_current, "DD-MM-YYYY"),
              moment(end_date_current, "DD-MM-YYYY"),
              "isoWeek",
              "[]"
            )
              ? "current"
              : "next";
            await this.getEventDetails(weekSelected);
            this.handleScheduleSubmit();
          } else {
            await this.getEventDetails(this.state.weekSelected);
          }
        }
      );
    }
  }

  /*
    Stores timeZone selected and converts the selected time slots(dates) according to timezone from 
    Previous option -> this.state.timezone ex: IST
    to 
    Current option -> e.target.value ex: PST
    Execution in steps IST -> CET
    Wed May 12 2021 09:00:00 GMT+0530 (India Standard Time) -> format("YYYY-MM-DD HH:mm")(To strip the GMT) -> 2021-05-12 09:00
    -> Time Zone conversion happens -> Wed May 12 2021 05:30:00 GMT+0200 ->format("ddd MMM DD YYYY HH:mm:ss")-> Wed May 12 2021 05:30:00 -> Wed May 12 2021 05:30:00 GMT+0530 (India Standard Time)(It is shown as IST because new Date() considers local system timezone, but the date object will be in the form of CET)
    Refer
    https://momentjs.com/timezone/
    https://momentjs.com/timezone/docs/
  */

  handleTimeZoneSelector = (e) => {
    let { eventDetails } = this.state;

    let convertedTime = momentTimeZone
      .tz(eventDetails.eventStartDt, TIMEZONES[this.state.eventTimeZone])
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("ddd MMM DD YYYY@HH:mm");
    let startDate = new Date(convertedTime.split("@")[0]);

    let convertedEntries = {};
    for (let entry in this.state.eventDetails.entries) {
      let convertedKey = momentTimeZone
        .tz(entry, "DD-MM-YYYY_HH:mm", TIMEZONES[this.state.timezone])
        .clone()
        .tz(TIMEZONES[e.target.value])
        .format("DD-MM-YYYY_HH:mm");
      convertedEntries[convertedKey] = this.state.eventDetails.entries[entry];
    }
    this.setState({
      schedule: this.state.schedule.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      timezone: e.target.value,
      eventDetails: {
        ...this.state.eventDetails,
        startDate,
        entries: convertedEntries,
      },
    });
  };

  /*
        This function will be called when the user clicks a block on the scheduler.
        Controlled by react-schedule-selector.
        @params {Array<Date>} newSchedule
        Restrics the owner from choosing a particular time slot, if
        1) The date is a past date
        2) If no user is available at that slot
        3) if the selected blocks are not continuous
    */

  handleScheduleChange = (newSchedule) => {
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (this.state.schedule.length < newSchedule.length) {
      const selected_date = newSchedule[newSchedule.length - 1];
      const time_diff = selected_date - newSchedule[newSchedule.length - 2];
      const formatted_date = moment(selected_date).format("DD-MM-YYYY_HH:mm");
      const count =
        this.state.eventDetails.entries[formatted_date]?.length || 0;
      if (+currentDateInTz > +selected_date) {
        showAlert("error", "Past time cannot be selected");
        return;
      } else if (count === 0) {
        const isProceed = window.confirm(
          "No one is available at this slot, if you want to proceed, click ok else cancel"
        );
        if (!isProceed) {
          return;
        }
      } else if (time_diff) {
        const time_diff_in_mins = Math.abs(time_diff / 1000 / 60);
        const time_interval =
          time_interval_in_mins[this.state.eventDetails.timeInterval];
        if (time_interval !== time_diff_in_mins) {
          showAlert("error", "Slot should be selected sequentially");
          return;
        }
      }
    }
    newSchedule = newSchedule.filter((date) => currentDateInTz < date);
    this.setState({ schedule: newSchedule });
  };

  /*
        Validates the data. Checks are
        1) If the owner wants to remove the time slots after scheduling the event, click Ok in the confirm dialog
        2) If the owner is scheduling time slot for first time, check atleast one time slot is selected or not
        3) Check whether the slots are continuous
    */

  validateData = () => {
    if (
      this.state.schedule.length === 0 &&
      Object.keys(this.props.meetingsData).length === 0
    ) {
      showAlert("error", "Select a slot to schedule");
      return false;
    }

    if (Object.keys(this.props.meetingsData).length === 0) {
      const sorted_dates = this.state.schedule.sort((a, b) => a - b);
      const time_interval =
        time_interval_in_mins[this.state.eventDetails.timeInterval];
      for (let i = 0; i < sorted_dates.length - 1; i++) {
        const time_diff_in_mins = Math.abs(
          (sorted_dates[i + 1] - sorted_dates[i]) / 1000 / 60
        );
        if (time_interval !== time_diff_in_mins) {
          showAlert("error", "Slot should be selected sequentially");
          return false;
        }
      }
    }
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    for (let date of this.state.schedule) {
      if (date < currentDateInTz) {
        showAlert("error", "Past time cannot be selected");
        return false;
      }
    }
    return true;
  };

  /*
    Get notes and todoItem created by admins if any to show
  */

  getNotes = async () => {
    let url = `${API_URL_V2}/scheduler/weekly/getnotes`;
    const headers = {
      "auth-token": this.auth_token,
    };
    try {
      const { data } = await Network(url, "GET", null, headers);
      this.setState({
        todoItems: data.checklists?.data || [],
        notes: data.notes?.data || [],
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      showAlert("error", "Some error occured, try again!");
    }
  };

  getCommonAttendees = () => {
    const { schedule, eventDetails } = this.state;
    let formatted_date = moment(schedule[0]).format("DD-MM-YYYY_HH:mm");
    let common_attendees = eventDetails.entries[formatted_date] || [];
    for (let i = 1; i < schedule.length; i++) {
      formatted_date = moment(schedule[i]).format("DD-MM-YYYY_HH:mm");
      common_attendees = intersection(
        common_attendees,
        eventDetails.entries[formatted_date] || []
      );
    }
    return common_attendees;
  };

  /*
        This function will be called when schedule button is clicked by owner.
    */

  handleScheduleSubmit = async (e) => {
    const { schedule } = this.state;
    const isValid = this.validateData();
    if (!isValid) {
      return;
    }
    let start_time = "";
    let end_time = "";
    let common_attendees = [];
    if (Object.keys(this.props.meetingsData).length > 0) {
      this.setState(
        {
          schedule: [
            this.props.meetingsData.start,
            new Date(
              moment(this.props.meetingsData.end).subtract(30, "minutes")
            ),
          ],
          timezone: this.props.meetingsData.timezone,
        },
        () => {
          // By sorting all the entries, we can find the start time and end time of the meeting
          start_time = moment(this.state.schedule[0]).format(
            "DD-MM-YYYY hh:mm A"
          );
          end_time = moment(this.state.schedule[1])
            .add(30, "minutes")
            .format("DD-MM-YYYY hh:mm A");

          common_attendees = this.getCommonAttendees();
          this.setState({
            meetingDetails: {
              ...this.state.meetingDetails,
              start_time,
              end_time,
            },
          });
        }
      );
    } else {
      // By sorting all the entries, we can find the start time and end time of the meeting
      let sorted_dates = schedule.sort((a, b) => a - b);
      start_time = moment(sorted_dates[0]).format("DD-MM-YYYY hh:mm A");
      end_time = moment(sorted_dates[sorted_dates.length - 1]);
      if (Object.keys(this.props.meetingsData).length === 0) {
        end_time = end_time.add(30, "minutes").format("DD-MM-YYYY hh:mm A");
      } else {
        end_time = end_time.format("DD-MM-YYYY hh:mm A");
      }
      common_attendees = this.getCommonAttendees();
      this.setState({
        meetingDetails: {
          ...this.state.meetingDetails,
          start_time,
          end_time,
        },
      });
    }

    this.setState(
      {
        currentScreen: 2,
        attendeesOption: 1,
        meetingDetails: {
          ...this.state.meetingDetails,
          eligibleAttendees: common_attendees.map((id) => ({
            value: id,
            label: this.props.admins[id],
          })),
          availableAttendees: common_attendees,
          selectedAttendees: [],
          project: "",
          meetingFrequency: "0",
          meetingRepeatEvery: 1,
          meetingMonthlyOption: "on day",
          meetingRepeatOnDay: 1,
          meetingRepeatOnTheNth: "1",
          meetingRepeatOnTheDay: "SU",
          meetingRepeatOption: "DAILY",
          meetingSelectedDay: [],
          meetingEnd: "never",
          meetingEndOn: "",
          meetingEndAfter: 5,
          start_time,
          end_time,
        },
      },
      () => this.handleModal("showDetailsModal")
    );
  };

  /*
    Changes screen in Meeting Creation modal
  */

  handleChangeScreen = async (e) => {
    this.setState({
      currentScreen: this.state.currentScreen + 1,
    });
  };

  /*
    Handles back button press in Meeting creation screens
  */

  handleBack = async (e) => {
    this.setState(
      {
        currentScreen: this.state.currentScreen - 1,
      },
      () => {
        if (this.state.currentScreen === 1) {
          this.handleModal("showDetailsModal");
        }
      }
    );
  };

  /*
        This function will be called when the owner hover over a particular block.
        Used to show the available users at a particular time slot
    */

  handleMouseOver = (formatted_date, e) => {
    e.preventDefault();
    //e.stopImmediatePropagation();
    e.stopPropagation();
    this.setState({
      selectedDate: formatted_date,
    });
  };

  /*
        This function will be called when the owner hover away from a particular block.
        The status of registered users will be rendered back to just names.No tick or X 
        if selectedDate is not cleared, it will show the status of users at the last hovered block
    */

  handleMouseOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      selectedDate: "",
    });
  };

  /*
    This function is used to render the blocks in the scheduler.
    if the date is not available in the entries,that means no user chose that time slot. So count -> 0 and color -> grey
    n = total no of registered users -> this.state.eventDetails.totalNoOfUsers
    x = total no of users available at a particular time slot -> this.state.eventDetails.entries[formatted_date].length
    if (n-2 < x) -> color -> green
    if (x > 1 && x < = n -2) -> color -> blue
    if (x == 1 or 0) color -> grey
    if the data is past date, color -> red
*/

  customDateCellRenderer = (date, selected, refSetter) => {
    const {
      eventDetails: { entries, totalNoOfUsers, userNames },
      userEntries,
      userSelected,
      selectedDate,
      teamSelected,
      teamData: { teamEntries },
    } = this.state;
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    let color = "";
    let availablePeople = 0;
    const formatted_date = moment(date).format("DD-MM-YYYY_HH:mm");
    if (!userSelected) {
      const _entries = !teamSelected ? entries : teamEntries;
      const keys = Object.keys(_entries);
      if (keys.indexOf(formatted_date) !== -1) {
        availablePeople = _entries[formatted_date].length;
        if (totalNoOfUsers - 2 < availablePeople) {
          color = "#00ff00";
        } else if (
          availablePeople > 1 &&
          availablePeople <= totalNoOfUsers - 2
        ) {
          color = "rgb(49 229 247)";
        } else if (availablePeople <= 1) {
          color = "#d3d3d3";
        }
      } else {
        color = "#d3d3d3";
        availablePeople = 0;
      }
      if (currentDateInTz > date) {
        color = "#ff0000";
      }
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              <div className="d-flex flex-column justify-content-start">
                {userNames &&
                  (_entries?.[selectedDate]?.length ? (
                    _entries[selectedDate]?.map((key, i) => (
                      <div key={i} className="user-name">
                        {i + 1}) {userNames[key].split("@")[0]}{" "}
                      </div>
                    ))
                  ) : (
                    <div className="user-name">
                      {selectedDate ? "No one available" : "Loading..."}{" "}
                    </div>
                  ))}
              </div>
            </Tooltip>
          }
        >
          <div
            style={{ backgroundColor: color }}
            className={`time-block ${selected ? "selected-indicator" : ""}`}
            ref={refSetter}
            onMouseEnter={(e) => this.handleMouseOver(formatted_date, e)}
            onMouseLeave={this.handleMouseOut}
            onTouchStart={(e) => this.handleMouseOver(formatted_date, e)}
            onTouchEnd={this.handleMouseOut}
          >
            <div className="available-people-indicator">{availablePeople}</div>
          </div>
        </OverlayTrigger>
      );
    } else {
      if (userEntries.indexOf(formatted_date) !== -1) {
        color = "#00ff00";
      } else {
        color = "#d3d3d3";
      }
      if (currentDateInTz > date) {
        color = "#ff0000";
      }
      return (
        <div
          style={{ backgroundColor: color }}
          className={`time-block ${selected ? "selected-indicator" : ""}`}
          ref={refSetter}
        >
          <span style={{ color }}>.</span>
        </div>
      );
    }
  };

  /*
    Renders UI based on week selected
  */

  handleWeekSelection = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        isLoading: true,
        userSelected: "",
        userEntries: [],
      },
      async () => {
        this.handleTeamSelection({ target: { value: "" } });
        await this.getEventDetails(this.state.weekSelected);
      }
    );
  };

  /*
    Handles Todo and notes input changes
  */

  handleModalsInput = ({ target: { value, name } }) => {
    this.setState({
      [name]: value,
    });
  };

  /*
    Handles input changes in Meeting creation modal
  */

  handleInputChange = ({ target: { value, name } }) => {
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        [name]: value,
      },
    });
  };

  /*
    Handle multi select attendees dropdown input change
  */

  handleChange = (selectedOption) => {
    // if (!this.state.meetingDetails.project) {
    //   showAlert("error", "Select project first");
    //   return;
    // }
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        selectedAttendees: selectedOption,
      },
    });
  };

  /*
    handle multi select Repeat on days option change
  */

  handleMeetingDay = (selectedOption) => {
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        meetingSelectedDay: selectedOption,
      },
    });
  };

  /*
    Handles proceed button click in Meeting creation modal
    Validates data and renders the next screen
  */

  handleProceed = () => {
    const {
      title,
      description,
      selectedAttendees,
      project,
      meetingFrequency,
      meetingEnd,
      meetingEndAfter,
      meetingEndOn,
      meetingRepeatOption,
      meetingSelectedDay,
    } = this.state.meetingDetails;
    const { attendeesOption } = this.state;
    if (title.trim() === "") {
      showAlert("error", "Enter a title");
      return;
    } else if (description.length === 1 && description[0].val.trim() === "") {
      showAlert("error", "Enter at least one agenda point");
      return;
    } else if (selectedAttendees.length === 0) {
      showAlert("error", "Atleast one attendee should be selected");
      return;
    } else if (project.length === 0) {
      showAlert("error", "Atleast one project should be selected");
      return;
    } else if (meetingFrequency === "4") {
      if (meetingEnd === "on" && !meetingEndOn) {
        showAlert("error", "Please select a date");
        return;
      } else if (
        meetingEnd === "on" &&
        !moment(meetingEndOn, "YYYY-MM-DD", true).isValid()
      ) {
        showAlert("error", "Date is invalid");
        return;
      } else if (
        meetingEnd === "on" &&
        moment(meetingEndOn, "YYYY-MM-DD").diff(moment()) < 0
      ) {
        showAlert("error", "Past date cannot be selected");
        return;
      } else if (meetingEnd === "after" && meetingEndAfter === 0) {
        showAlert("error", "Occurence cannot be 0 !");
        return;
      } else if (
        meetingRepeatOption === "WEEKLY" &&
        meetingSelectedDay.length === 0
      ) {
        showAlert("error", "Please select a day");
        return;
      }
    }
    for (let attendee of selectedAttendees) {
      const email = !attendee.label.includes("_")
        ? attendee.label
        : attendee.label.split("_")[1];
      if (
        attendee.__isNew__ &&
        Object.keys(this.props.adminNames).includes(email)
      ) {
        showAlert(
          "error",
          `${email} email belongs to already registered console user - ${this.props.adminNames[email]}, so please select the attendee from All or Available option
         ;`
        );
        return;
      }

      if (!emailRegex.test(email)) {
        showAlert("error", `${email} is not valid`);
        return;
      }
    }
    let formattedDescription = "Meeting Agenda: \n";

    if (description.length === 1 && description[0].val.trim() === "") {
      formattedDescription += "Not available \n";
    } else {
      for (let i = 0; i < description.length; i++) {
        if (description[i].val.trim() === "") {
          showAlert("error", "Fill all the agenda points");
          return;
        }
        formattedDescription += `${i + 1}) ${description[i].val} \n`;
      }
    }
    if (attendeesOption === 2) {
      const isProceed = window.confirm(
        "You have selected All option, so be aware that attendee(s) may already have meeting(s) at this time or may not be available, if you selected All option by mistake, click `cancel` and select 'Available' option else click `ok` and proceed"
      );
      if (!isProceed) {
        return;
      }
    }

    const _selectedAttendees = selectedAttendees.map((attendee) => {
      if (attendee.__isNew__) {
        const email = !attendee.label.includes("_")
          ? attendee.label
          : attendee.label.split("_")[1];
        const name = !attendee.label.includes("_")
          ? attendee.label.split("@")[0]
          : attendee.label.split("_")[0];
        return {
          value: Math.random(),
          label: `${name}_${email}`,
        };
      }
      return attendee;
    });

    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        formattedDescription,
        selectedAttendees: [..._selectedAttendees],
      },
    });

    this.handleChangeScreen();
  };

  /*
    Calls the API to create the meeting in Google calendar and redirects to meeting page
  */

  saveSchedule = async (postData) => {
    try {
      const { selectedTodoIndex } = this.state;
      this.handleModal("showDetailsModal");
      const url = `${API_URL_V2}/scheduler/weekly/schedule`;
      const headers = {
        "auth-token": this.auth_token,
      };
      const res = await Network(url, "POST", postData, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        this.setState({ isLoading: false });
        if (selectedTodoIndex != null) {
          const newTodos = [...this.state.todoItems];
          newTodos[selectedTodoIndex].isDone = true;
          this.setState(
            {
              todoItems: newTodos,
            },
            async () => {
              await this.saveData(false);
              this.props.handleSwitchView("meetings");
            }
          );
        } else {
          this.props.handleSwitchView("meetings");
        }
      } else if (res.statusCode === STATUS_CODES.NOT_AUTHORIZED) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
        window.location.href = "/";
      } else {
        showAlert("error", res.status);
        this.setState({ isLoading: false });
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.handleModal("showDetailsModal");
      showAlert("error", err);
    }
  };

  /*
    Handles confirm button click meeting details verify screen
    and calls saveSchedule func
  */

  handleConfirm = () => {
    const { meetingDetails, timezone, eventTimeZone, schedule } = this.state;
    this.setState({
      isLoading: true,
    });
    const postData = {
      ...meetingDetails,
      entries: schedule.map((date) => {
        return momentTimeZone
          .tz(moment(date).format("YYYY-MM-DD HH:mm"), TIMEZONES[timezone])
          .clone()
          .tz(TIMEZONES[eventTimeZone])
          .format("DD-MM-YYYY_HH:mm");
      }),
      attendees: meetingDetails.selectedAttendees.map((attendee) => ({
        displayName: attendee.label.split("_")[0],
        email: attendee.label.split("_")[1],
      })),
      meeting_title: meetingDetails.title,
      meeting_description: meetingDetails.formattedDescription,
      start_time: momentTimeZone
        .tz(
          moment(meetingDetails.start_time, "DD-MM-YYYY hh:mm A").format(
            "YYYY-MM-DD HH:mm"
          ),
          TIMEZONES[timezone]
        )
        .clone()
        .tz(TIMEZONES[eventTimeZone])
        .format("DD-MM-YYYY_HH:mm"),
      end_time: momentTimeZone
        .tz(
          moment(meetingDetails.end_time, "DD-MM-YYYY hh:mm A").format(
            "YYYY-MM-DD HH:mm"
          ),
          TIMEZONES[timezone]
        )
        .clone()
        .tz(TIMEZONES[eventTimeZone])
        .format("DD-MM-YYYY_HH:mm"),
      team: meetingDetails.project,
      meetingSelectedDay: meetingDetails.meetingSelectedDay
        .map((day) => day.value)
        .join(","),
      meetingEndOn: `${moment(meetingDetails.meetingEndOn, "YYYY-MM-DD").format(
        "YYYYMMDD"
      )}`,
      day: moment(meetingDetails.start_time, "DD-MM-YYYY hh:mm A")
        .format("dddd")
        .toUpperCase()
        .slice(0, 2),
      timezone: TIMEZONES[eventTimeZone],
    };
    delete postData["title"];
    delete postData["description"];
    delete postData["formattedDescription"];
    delete postData["availableAttendees"];
    delete postData["selectedAttendees"];
    delete postData["eligibleAttendees"];
    this.saveSchedule(postData);
  };

  /*
    Handles select and deselect all in Meeting attendees selection dropdown
  */

  handleSelectAll = (e) => {
    e.preventDefault();
    if (!this.state.meetingDetails.project) {
      showAlert("error", "Select project first");
    }
    this.setState({
      isSelectAll: !this.state.isSelectAll,
      meetingDetails: {
        ...this.state.meetingDetails,
        selectedAttendees: !this.state.isSelectAll
          ? [...this.state.meetingDetails.eligibleAttendees]
          : [],
      },
    });
  };

  /*
    Shows entries belonging to a particular user
  */

  handleUserSelection = ({ target: { value: userSelected } }) => {
    let userEntries = [];
    const {
      eventDetails: { entries },
      teamSelected,
      teamData: { teamEntries },
    } = this.state;
    if (!userSelected) {
      this.setState({
        userEntries: [],
        userSelected,
        schedule: [],
      });
      return;
    }
    const _entries = !teamSelected ? entries : teamEntries;
    for (let key of Object.keys(_entries)) {
      if (
        _entries[key].indexOf(userSelected) !== -1 ||
        _entries[key].indexOf(+userSelected) !== -1
      ) {
        userEntries.push(key);
      }
    }
    this.setState({
      userEntries,
      userSelected,
      schedule: [],
    });
  };

  /*
    Shows entries belonging to a particular team/project
  */

  handleTeamSelection = ({ target: { value } }) => {
    if (value) {
      const {
        eventDetails: { entries },
        teamData: { teamEntries },
      } = this.state;
      const team = this.props.teamDetails.filter(
        (team) => team.short_name === value
      );
      for (let key of Object.keys(entries)) {
        teamEntries[key] = intersection(
          team[0].project_member_ids.map((v) => +v),
          entries[key].map((v) => +v)
        );
        if (teamEntries[key].length === 0) {
          delete teamEntries[key];
        }
      }
      this.setState({
        teamSelected: value,
        schedule: [],
        teamData: {
          ...this.state.teamData,
          teamEntries,
          teamMembers: team[0].project_member_ids,
        },
      });
      return;
    } else {
      this.setState({
        teamSelected: value,
        schedule: [],
        teamData: {
          ...this.state.teamData,
          teamEntries: {},
          teamMembers: [],
        },
      });
    }
  };

  /*
    Shows admins belonging to a particular project after selecting the project in meeting creation modal
    Only users belonging to a particular project can schedule a meeting for that project
  */

  handleAttendeesOptionChange = async ({ target: { value } }) => {
    const {
      meetingDetails: { availableAttendees },
    } = this.state;
    if (+value === 1) {
      this.setState({
        meetingDetails: {
          ...this.state.meetingDetails,
          eligibleAttendees: [...availableAttendees]
            .map((admin_id) => ({
              value: admin_id,
              label: this.props.admins[admin_id],
            }))
            .filter((admin) => admin.label && admin.value),
          selectedAttendees: [],
        },
      });
    } else if (+value === 2) {
      this.setState({
        meetingDetails: {
          ...this.state.meetingDetails,
          eligibleAttendees: Object.keys(this.props.admins).map((id) => ({
            value: id,
            label: this.props.admins[id],
          })),
          selectedAttendees: [],
        },
      });
    }
    this.setState({
      attendeesOption: +value,
      isSelectAll: false,
    });
  };

  /*
    Handles opening/closing all the modals used in this screen
  */

  handleModal = async (flag) => {
    if (flag === "showNotesModal" && !this.state.showNotesModal) {
      await this.getNotes();
    }
    if (flag === "showDetailsModal" && this.state.showDetailsModal) {
      this.setState({
        meetingDetails: {
          ...this.state.meetingDetails,
          title: "",
        },
        selectedTodoIndex: null,
        currentScreen: 1,
        schedule: [],
      });
    }
    if (
      flag === "showDetailsModal" &&
      this.state[flag] &&
      Object.keys(this.props.meetingsData).length
    ) {
      alert("Redirecting to meetings page in 5 seconds...");
      setTimeout(() => this.props.handleSwitchView("meetings"), 5000);
    }
    this.setState({
      [flag]: !this.state[flag],
    });
  };

  /*
    Saves notes and todo in state
  */

  addItems = (flag) => {
    if (flag === 1) {
      const { todoText } = this.state;
      if (todoText) {
        this.setState({
          todoText: "",
          todoItems: [
            ...this.state.todoItems,
            {
              text: todoText,
              created_at: moment(),
              created_by: this.state.adminId,
              isDone: false,
            },
          ],
        });
      }
    } else {
      const { notesText } = this.state;
      if (notesText)
        this.setState({
          notesText: "",
          notes: [
            ...this.state.notes,
            {
              text: notesText,
              created_at: moment(),
              created_by: this.state.adminId,
              isDone: false,
            },
          ],
        });
    }
  };

  /*
    Changes done status of the todos/notes
  */

  markItem = (index, flag) => {
    if (flag === 1) {
      const newTodos = [...this.state.todoItems];
      newTodos[index].isDone = !newTodos[index].isDone;
      this.setState({
        todoItems: newTodos,
      });
    } else {
      const notes = [...this.state.notes];
      notes[index].isDone = !notes[index].isDone;
      this.setState({
        notes,
      });
    }
  };

  /*
    Removes todos and notes from the state
  */

  removeItem = (index, flag) => {
    if (flag === 1) {
      const newTodos = [...this.state.todoItems];
      newTodos.splice(index, 1);
      this.setState({
        todoItems: newTodos,
      });
    } else {
      const notes = [...this.state.notes];
      notes.splice(index, 1);
      this.setState({
        notes,
      });
    }
  };

  /*
    This step only creates the notes/todos in database, 
    all the previous functions only store the data in state
  */

  saveData = async (updateState = true) => {
    try {
      const url = `${API_URL_V2}/scheduler/weekly/savenotes`;
      const headers = {
        "auth-token": this.auth_token,
      };
      const { todoItems, notes } = this.state;
      const postData = {
        notes,
        todos: todoItems,
      };

      const response = await Network(url, "POST", postData, headers);
      if (response.statusCode === STATUS_CODES.CREATED) {
        if (updateState) showAlert("success", "Successfully saved!");
      } else {
        showAlert("error", response.status);
      }
      if (updateState) this.handleModal("showNotesModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again!");
    }
  };

  /*
    By clicking the tick button after selecting a slot, meeting modal will open with todo text as the meeting title
  */

  scheduleWithNotes = (index) => {
    if (this.state.todoItems[index].isDone) {
      showAlert("error", "It is already marked as scheduled");
      return;
    }
    if (this.state.schedule.length === 0) {
      showAlert("error", "Select some slots first");
      return;
    }

    if (this.state.currentScreen !== 1) {
      showAlert(
        "error",
        "You are already in schedule page, go back to scheduler"
      );
      return;
    }
    this.setState(
      {
        meetingDetails: {
          ...this.state.meetingDetails,
          title: this.state.todoItems[index].text,
        },
      },
      () => {
        this.setState({
          showNotesModal: false,
          selectedTodoIndex: index,
        });
        this.handleScheduleSubmit();
      }
    );
  };

  /*
    Saves Agenda in state
  */

  handleAgendaChange = (i, e) => {
    let description = [...this.state.meetingDetails.description];
    let agenda = description[i];
    agenda["val"] = e.target.value;
    description[i] = agenda;
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        description,
      },
    });
  };

  /*
    Adds a new field/point to agenda
  */

  handleAddAgenda = () => {
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        description: [...this.state.meetingDetails.description, { val: "" }],
      },
    });
  };

  /*
    Removes the field/point from agenda fields
  */

  handleRemoveAgenda = (i) => {
    if (this.state.meetingDetails.description.length === 1) {
      return;
    }
    let description = [...this.state.meetingDetails.description];
    description.splice(i, 1);
    this.setState({
      meetingDetails: {
        ...this.state.meetingDetails,
        description,
      },
    });
  };

  render() {
    const {
      eventDetails,
      userName,
      isLoading,
      schedule,
      timezone,
      weekSelected,
      currentScreen,
      meetingDetails,
      isSpecialAdmin,
      userSelected,
      teamSelected,
      teamData: { teamMembers },
      attendeesOption,
      isSelectAll,
      showNotesModal,
      todoItems,
      todoText,
      notesText,
      notes,
      showDetailsModal,
      showNewReleaseInfoModal,
    } = this.state;
    let maxTime;
    if (eventDetails.maxTime) {
      if (eventDetails.minTime >= eventDetails.maxTime)
        maxTime = Math.floor(24 + eventDetails.maxTime);
      else maxTime = eventDetails.maxTime;
    }

    let userNames = [];
    if (teamSelected && eventDetails.userNames && teamMembers?.length !== 0) {
      userNames = intersection(
        Object.keys(eventDetails.userNames),
        teamMembers.map((v) => String(v))
      );
    } else {
      userNames = Object.keys(eventDetails.userNames || {});
    }
    return (
      <>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <>
            <Form.Group
              controlId="formGroupScheme"
              className="timezone-selector-team"
            >
              <Form.Control
                as="select"
                name="timezone"
                value={timezone}
                onChange={this.handleTimeZoneSelector}
              >
                {Object.keys(TIMEZONES).map((timezone, i) => (
                  <option value={timezone} key={i}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className="helper-text-team">
              Hi {userName}! Click a particular slot to see the available users
              at that slot
            </div>
            <div className="view-container align-items-start">
              <div className="team-schedule-selector">
                <div className="team-select-group">
                  <Form.Group
                    controlId="formGroupScheme"
                    className="selection-scheme-team week-selector"
                  >
                    <Form.Control
                      as="select"
                      name="weekSelected"
                      value={weekSelected}
                      onChange={this.handleWeekSelection}
                    >
                      <option value="current">Current week</option>
                      <option value="next">Next week</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="formGroupScheme"
                    className="selection-scheme-team"
                  >
                    <Form.Control
                      as="select"
                      name="userSelected"
                      value={userSelected}
                      onChange={this.handleUserSelection}
                    >
                      <option value="">All</option>
                      {eventDetails.userNames &&
                      Object.keys(eventDetails.userNames).length > 0 ? (
                        userNames?.map((key, i) => (
                          <option key={eventDetails.userNames[key]} value={key}>
                            {
                              eventDetails.userNames[key]
                                .split("@")[0]
                                .split("_")[1]
                            }
                          </option>
                        ))
                      ) : (
                        <option value="">None</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="formGroupScheme"
                    className="selection-scheme-team"
                  >
                    <Form.Control
                      as="select"
                      name="teamSelected"
                      value={teamSelected}
                      onChange={this.handleTeamSelection}
                      disabled={!!userSelected}
                    >
                      <option value=""> All </option>
                      {this.props.teamDetails.map((team) => (
                        <option key={team.short_name} value={team.short_name}>
                          {team.short_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <ScheduleSelector
                  selection={schedule}
                  startDate={eventDetails.startDate}
                  numDays={eventDetails.numDays}
                  minTime={eventDetails.minTime}
                  maxTime={maxTime}
                  timeFormat="h:mm A"
                  dateFormat="MMM DD - ddd"
                  hourlyChunks={eventDetails.timeInterval}
                  onChange={this.handleScheduleChange}
                  renderDateCell={this.customDateCellRenderer}
                />
                {isSpecialAdmin && (
                  <div className="text-center py-5">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={this.handleScheduleSubmit}
                    >
                      Schedule
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <Modal
          show={showNotesModal}
          onHide={() => this.handleModal("showNotesModal")}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Tabs>
              <TabList>
                <Tab>Add Notes</Tab>
                <Tab>Notes</Tab>
                <Tab>Checklist</Tab>
              </TabList>

              <TabPanel>
                <Form.Control
                  as="textarea"
                  placeholder="Type here"
                  style={{ height: "100px" }}
                  name="notesText"
                  value={notesText}
                  onChange={this.handleModalsInput}
                />
                <div className="text-center">
                  <Button
                    variant="outline-success"
                    onClick={() => this.addItems(0)}
                    className="w-25 my-2"
                  >
                    Add
                  </Button>
                </div>
              </TabPanel>
              <TabPanel>
                {notes.length !== 0 ? (
                  notes
                    .sort((x, y) => (x.isDone === y.isDone ? 0 : x ? 1 : -1))
                    .map((note, index) => (
                      <div className="todo" key={index}>
                        <span
                          style={{
                            textDecoration: note.isDone ? "line-through" : "",
                            wordWrap: "break-word",
                            cursor: "pointer",
                          }}
                          className="ml-1 w-75 bg-info p-1"
                          onClick={() => this.markItem(index, 0)}
                        >
                          {note.text}
                        </span>
                        <div className="w-25 text-right">
                          <Button
                            variant="outline-danger"
                            onClick={() => this.removeItem(index, 0)}
                          >
                            ✕
                          </Button>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="text-center">Add notes to see here</div>
                )}
              </TabPanel>
              <TabPanel>
                {todoItems
                  .sort((x, y) => (x === y ? 0 : x ? 1 : -1))
                  .map((todo, index) => (
                    <div className="todo" key={index}>
                      <span
                        style={{
                          textDecoration: todo.isDone ? "line-through" : "",
                          wordWrap: "break-word",
                          cursor: "pointer",
                        }}
                        className="ml-1 w-75 bg-info p-1"
                        onClick={() => this.markItem(index, 1)}
                      >
                        {todo.text}
                      </span>
                      <div className="w-25 text-right">
                        {!todo.isDone && (
                          <Button
                            variant="outline-success"
                            onClick={() => this.scheduleWithNotes(index)}
                          >
                            ✓
                          </Button>
                        )}{" "}
                        <Button
                          variant="outline-danger"
                          onClick={() => this.removeItem(index, 1)}
                        >
                          ✕
                        </Button>
                      </div>
                    </div>
                  ))}
                <div className="d-flex align-items-center">
                  <Form.Control
                    className="w-75"
                    type="text"
                    placeholder="Type here and add"
                    name="todoText"
                    value={todoText}
                    onChange={this.handleModalsInput}
                  />
                  <Button
                    variant="outline-success"
                    onClick={() => this.addItems(1)}
                    className="w-25 ml-2"
                  >
                    Add
                  </Button>
                </div>
              </TabPanel>
            </Tabs>
            <Modal.Footer>
              <div className="text-center w-100">
                <Button
                  variant="outline-success"
                  className="w-50 mt-2"
                  onClick={this.saveData}
                >
                  Save All
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <div
          className="notes-icon"
          onClick={() => this.handleModal("showNotesModal")}
        >
          <CgNotes style={{ fontSize: "1.5em", color: "#e9a431" }} />
        </div>
        <Modal
          size="lg"
          show={showDetailsModal}
          onHide={() => this.handleModal("showDetailsModal")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Meeting Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentScreen === 2 ? (
              <>
                <div className="meeting-form-header">
                  Fill meeting details to Schedule
                </div>
                <div className="meeting-time-header">
                  <span className="font-weight-bold">Meeting Time : </span>
                  <span>
                    {meetingDetails.start_time} - {meetingDetails.end_time}
                  </span>{" "}
                  <span className="font-weight-bold">{timezone}</span>
                </div>
                <div className="create-meeting-form">
                  <Form onSubmit={this.submitAndCopyInvitationURL}>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter a Meeting Title"
                        name="title"
                        value={meetingDetails.title}
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label className="font-weight-bold">
                        Agenda{" "}
                        <MdAddCircle
                          className="cursor-pointer"
                          onClick={this.handleAddAgenda}
                        />
                      </Form.Label>
                      {meetingDetails.description.map((description, i) => (
                        <div
                          className="d-flex align-items-center cursor-pointer"
                          key={i}
                        >
                          <Form.Control
                            type="text"
                            placeholder={`Point ${i + 1}`}
                            name="description"
                            value={description.val}
                            onChange={(e) => this.handleAgendaChange(i, e)}
                          />
                          {i !== 0 && (
                            <MdOutlineRemoveCircle
                              onClick={() => this.handleRemoveAgenda(i)}
                            />
                          )}
                        </div>
                      ))}
                    </Form.Group>

                    <Form.Group controlId="formGroupStartTime">
                      <Form.Label className="font-weight-bold">
                        Choose a project
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="project"
                        value={meetingDetails.project}
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled={true}>
                          Select
                        </option>
                        {this.props.teamDetails.map((team) => (
                          <option key={team.short_name} value={team.short_name}>
                            {team.short_name}
                          </option>
                        ))}
                        <option value="others">OTHERS</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGroupStartTime">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span className="w-30 font-weight-bold">
                          Choose the attendees{" "}
                        </span>
                        <Form.Control
                          as="select"
                          name="attendeesOption"
                          style={{ width: "40%" }}
                          value={attendeesOption}
                          onChange={this.handleAttendeesOptionChange}
                        >
                          <option value={1}>Available</option>
                          <option value={2}>All (Not recommended)</option>
                        </Form.Control>
                        <span
                          className="cursor-pointer btn-link text-center"
                          onClick={this.handleSelectAll}
                        >
                          {!isSelectAll ? "Select all" : "Deselect all"}
                        </span>
                      </Form.Label>
                      <Select
                        isMulti={true}
                        isSearchable={true}
                        placeholder="Select or type an email and enter"
                        value={meetingDetails.selectedAttendees}
                        onChange={this.handleChange}
                        options={meetingDetails.eligibleAttendees}
                        disabled={!meetingDetails.project}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupStartTime">
                      <Form.Label className="font-weight-bold">
                        Meeting Frequency
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="meetingFrequency"
                        value={meetingDetails.meetingFrequency}
                        onChange={this.handleInputChange}
                      >
                        <option value="0">Doesn't repeat</option>
                        <option value="1">Daily</option>
                        <option value="2">
                          Weekly on{" "}
                          {moment(
                            meetingDetails.start_time,
                            "DD-MM-YYYY hh:mm A"
                          ).format("dddd")}
                        </option>
                        <option value="3">
                          Every weekday (Monday to Friday)
                        </option>
                        <option value="4">Custom</option>
                      </Form.Control>
                    </Form.Group>
                    {meetingDetails.meetingFrequency === "4" && (
                      <div>
                        <Form.Group controlId="formGroupStartTime">
                          <Form.Label className="d-flex justify-content-between align-items-center">
                            <span className="w-30 font-weight-bold">
                              Repeat every
                            </span>
                            <Form.Control
                              type="number"
                              min="1"
                              name="meetingRepeatEvery"
                              className="w-30"
                              value={meetingDetails.meetingRepeatEvery}
                              onChange={this.handleInputChange}
                            />
                            <Form.Control
                              as="select"
                              name="meetingRepeatOption"
                              className="w-30"
                              value={meetingDetails.meetingRepeatOption}
                              onChange={this.handleInputChange}
                            >
                              <option value="DAILY">Day</option>
                              <option value="WEEKLY">Week</option>
                              <option value="MONTHLY">Month</option>
                            </Form.Control>
                          </Form.Label>
                        </Form.Group>
                        {meetingDetails.meetingRepeatOption === "WEEKLY" && (
                          <>
                            <div className="font-weight-bold">Repeat on</div>
                            <Select
                              isMulti={true}
                              isSearchable={true}
                              placeholder="Should select atleast one day"
                              value={meetingDetails.meetingSelectedDay}
                              onChange={this.handleMeetingDay}
                              options={days}
                              disabled={!meetingDetails.project}
                            />
                          </>
                        )}
                        {meetingDetails.meetingRepeatOption === "MONTHLY" && (
                          <>
                            <div className="d-flex justify-content-between align-items-center">
                              <Form.Check
                                value="on day"
                                name="meetingMonthlyOption"
                                type="radio"
                                aria-label="radio 3"
                                label="Monthly on(Nth day)"
                                onChange={this.handleInputChange}
                                checked={
                                  meetingDetails.meetingMonthlyOption ===
                                  "on day"
                                }
                              />
                              <Form.Control
                                type="number"
                                min="1"
                                name="meetingRepeatOnDay"
                                className="w-30"
                                value={meetingDetails.meetingRepeatOnDay}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <Form.Check
                                value="on the"
                                name="meetingMonthlyOption"
                                type="radio"
                                aria-label="radio 3"
                                label="Monthly on the"
                                onChange={this.handleInputChange}
                                checked={
                                  meetingDetails.meetingMonthlyOption ===
                                  "on the"
                                }
                              />
                              <Form.Control
                                as="select"
                                name="meetingRepeatOnTheNth"
                                className="w-30"
                                value={meetingDetails.meetingRepeatOnTheNth}
                                onChange={this.handleInputChange}
                              >
                                <option value="1">First</option>
                                <option value="2">Second</option>
                                <option value="3">Third</option>
                                <option value="4">Fourth</option>
                                <option value="-1">Last</option>
                              </Form.Control>
                              <Form.Control
                                as="select"
                                name="meetingRepeatOnTheDay"
                                className="w-30"
                                value={meetingDetails.meetingRepeatOnTheDay}
                                onChange={this.handleInputChange}
                              >
                                {days.map((day) => (
                                  <option value={day.value}>{day.label}</option>
                                ))}
                                <option value="MO,TU,WE,TH,FR">Weekday</option>
                                <option value="SA,SU">Weekend day</option>
                              </Form.Control>
                            </div>
                          </>
                        )}
                        <div>
                          <div className="font-weight-bold mb-2">Ends</div>
                          <Form.Group controlId="kindOfStand">
                            <Form.Check
                              value="never"
                              name="meetingEnd"
                              type="radio"
                              aria-label="radio 1"
                              label="Never"
                              onChange={this.handleInputChange}
                              checked={meetingDetails.meetingEnd === "never"}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <Form.Check
                                value="on"
                                name="meetingEnd"
                                type="radio"
                                aria-label="radio 2"
                                label="On"
                                onChange={this.handleInputChange}
                                checked={meetingDetails.meetingEnd === "on"}
                              />
                              <Form.Control
                                type="date"
                                name="meetingEndOn"
                                className="w-30"
                                value={meetingDetails.meetingEndOn}
                                onChange={this.handleInputChange}
                                onKeyDown={(e) => e.preventDefault()}
                                min={moment()
                                  .add(1, "days")
                                  .format("YYYY-MM-DD")}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <Form.Check
                                value="after"
                                name="meetingEnd"
                                type="radio"
                                aria-label="radio 3"
                                label="After(no of occurences)"
                                onChange={this.handleInputChange}
                                checked={meetingDetails.meetingEnd === "after"}
                              />
                              <Form.Control
                                type="number"
                                min="1"
                                name="meetingEndAfter"
                                className="w-30"
                                value={meetingDetails.meetingEndAfter}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-around">
                      <div className="text-center pt-3 pb-3">
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                      </div>
                      <div className="text-center pt-3 pb-3">
                        <Button
                          variant="primary"
                          type="button"
                          onClick={this.handleProceed}
                        >
                          Proceed
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            ) : (
              <>
                <p className="meeting-form-header">
                  Check the meeting details and confirm. Can't be changed once
                  confirmed. Click back button to edit
                </p>
                <div className="confirm-details-view">
                  <div className="field-row">
                    <div className="field-header w-50">Title</div>
                    <div className="field-value">{meetingDetails.title}</div>
                  </div>
                  <div className="field-row">
                    <div className="field-header w-50">Agenda </div>
                    <div className="field-value">
                      {meetingDetails.description.map((desc, i) => (
                        <p key={i}>
                          {i + 1}) {desc.val || "Not available"}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="field-row">
                    <div className="field-header w-50">Project</div>
                    <div className="field-value">{meetingDetails.project}</div>
                  </div>
                  <div className="mb-3">
                    <p className="field-header text-center">Attendees</p>
                    <ol className="list-group list-group-numbered">
                      {meetingDetails.selectedAttendees
                        .map((attendee) => attendee.label)
                        .map((attendee, i) => (
                          <li className="list-group-item" key={i}>
                            <div>
                              <span className="font-weight-bold mr-3">
                                Name :
                              </span>
                              <span>{attendee.split("_")[0]}</span>
                            </div>
                            <div>
                              <span className="font-weight-bold mr-3">
                                Email :
                              </span>
                              <span>{attendee.split("_")[1]}</span>
                            </div>
                          </li>
                        ))}
                    </ol>
                  </div>
                  <div>
                    <p className="field-header text-center">Meeting Time</p>
                    <div className="card px-3 py-2 text-center">
                      <div>
                        <span>{meetingDetails.start_time} - </span>
                        <span>{meetingDetails.end_time}</span>{" "}
                        <span className="font-weight-bold">{timezone}</span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around pb-3">
                    <div className="text-center pt-3 pb-3">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={this.handleBack}
                      >
                        Back
                      </Button>
                    </div>
                    <div className="text-center pt-3 pb-3">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={this.handleConfirm}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={showNewReleaseInfoModal}
          onHide={() => this.handleModal("showNewReleaseInfoModal")}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h4>New Version 2.1 released!</h4>
              <p>List of changes</p>
              <ol>
                <li>
                  Now we can add other emails(Not registered in admin console)
                  in attendees dropdown by typing the email and pressing enter.
                  Admin console registered emails cannot be added manually by
                  typing, we have to use All or Available option for it
                </li>
                <li>
                  Now we can schedule meeting at slots where no users are
                  available, we can select the attendees using All option. This
                  option should not be used in all the cases and should be used
                  only when needed.
                </li>
                <li>
                  Added Month view in Meetings screen to see the scheduled
                  meetings for a month in a calendar view
                </li>
                <li>
                  Now we can see meetings scheduled in previous,current and next
                  month instead of current week/next week meetings
                </li>
                <li>Bug fixes and Improvements</li>
              </ol>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleModal("showNewReleaseInfoModal")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default TeamView;
