import React from "react";
import CreateEvent from "./containers/createEvent/createEvent";
import MainPage from "./containers/mainPage/mainPage";
import WeeklyScheduler from "./containers/WeeklyScheduler/weeklyScheduler";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends React.PureComponent {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={CreateEvent} />
            <Route path="/schedule/:id" component={MainPage} />
            <Route path="/weekly" component={WeeklyScheduler} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
        <ToastContainer />
      </>
    );
  }
}

export default App;
