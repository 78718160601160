//packages
import React, { Component } from "react";
import { Button, Alert, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ScheduleSelector from "react-schedule-selector";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";

//helpers
import { Network } from "../../utils/helpers";
import { STATUS_CODES, API_URL_V1, TIMEZONES } from "../../utils/constants";
import "./AdminView.css";

const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

const time_interval_in_mins = {
  1: 60,
  2: 30,
  3: 20,
  4: 15,
};

class AdminView extends Component {
  state = {
    userName: "",
    email: "",
    eventId: "",
    showScheduler: false,
    schedule: [],
    eventDetails: {},
    isLoading: true,
    selectedDate: "",
    isScheduled: false,
    isAlertOpen: false,
    timezone: "",
    eventTimeZone: "",
    updated_at: "",
  };

  /*
        Gets Event details to render the UI
        Entries are formatted from {"name":[dates]} -> ["date":[names]] for UI rendering
    */

  getEventDetails = async (eventId) => {
    try {
      const url = `${API_URL_V1}/scheduler/owner/event/${eventId}`;
      const token = sessionStorage.getItem("token");
      const headers = {
        Authorization: token,
      };
      const res = await Network(url, "GET", null, headers);

      if (res.statusCode === STATUS_CODES.SUCCESS) {
        const {
          entries,
          event_name,
          end_date,
          start_date,
          start_time,
          end_time,
          time_interval,
          name,
          email,
          timezone,
        } = res.data;
        let all_entries = [];
        let formatted_entries = {};
        for (let entry in entries) {
          if (Array.isArray(entries[entry])) {
            all_entries.push(...entries[entry]);
          } else {
            all_entries.push(...entries[entry]["entries"]);
          }
        }
        all_entries = [...new Set(all_entries)];
        for (let entry of all_entries) {
          formatted_entries[entry] = [];
          for (let name in entries) {
            if (Array.isArray(entries[name])) {
              if (entries[name].indexOf(entry) !== -1) {
                formatted_entries[entry].push(name);
              }
            } else {
              if (entries[name]["entries"].indexOf(entry) !== -1) {
                formatted_entries[entry].push(name);
              }
            }
          }
        }
        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${
          String(start_time).length === 1 ? "0" + start_time : start_time
        }:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${String(end_time).length === 1 ? "0" + end_time : end_time}:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES[timezone])
            .format("ddd MMM DD YYYY")
        );
        this.setState(
          {
            eventTimeZone: timezone,
            userName: name,
            email: email,
            eventDetails: {
              eventName: event_name,
              numDays:
                moment(end_date, "DD-MM-YYYY").diff(
                  moment(start_date, "DD-MM-YYYY"),
                  "days"
                ) + 1,
              eventStartDt,
              eventEndDt,
              startDate,
              minTime: Number(start_time),
              maxTime: Number(end_time),
              timeInterval: Number(time_interval),
              totalNoOfUsers: Object.keys(entries).length,
              userNames: Object.keys(entries).map((key) => key),
              entries: formatted_entries,
            },
            eventId,
            timezone,
          },
          () =>
            this.setState({
              isLoading: false,
            })
        );
      } else {
        alert(res.status);
        window.location.href = "/";
      }
    } catch (err) {
      alert(err);
      window.location.href = "/";
    }
  };

  /*
        Calls API to find whether this event is already scheduled by the owner
        to restrict the user from filling the availability
    */

  getEventScheduledStatus = async (eventId) => {
    try {
      const url = `${API_URL_V1}/scheduler/owner/event/${eventId}/entries`;
      const res = await Network(url, "GET");
      if (res.statusCode === 200) {
        let entries = res.data.entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          return +new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });
        this.setState({
          schedule: entries,
          isScheduled: true,
          isAlertOpen: true,
          updated_at: res.data.updated_at,
        });
      }
    } catch (err) {
      alert(err);
      window.location.href = "/";
    }
  };

  /*
    This function will be called by handleScheduleSubmit schedule button is clicked by owner.
    API is called to save the schedule
  */

  scheduleEvent = async (postData) => {
    try {
      const url = `${API_URL_V1}/scheduler/owner/event/${this.state.eventId}`;
      const token = sessionStorage.getItem("token");
      const headers = {
        Authorization: token,
      };
      const res = await Network(url, "POST", postData, headers);
      if (res.statusCode === STATUS_CODES.CREATED) {
        alert("Successfully saved");
        if (postData.entries.length !== 0) {
          this.setState({
            isAlertOpen: true,
            isScheduled: true,
            updated_at: res.data.updated_at,
          });
        }
      } else {
        alert(res.status);
      }
      this.setState({ isLoading: false });
    } catch (err) {
      alert(err);
    }
  };

  /*
        Renders the UI if the user is owner or redirects the user to home page
    */

  async componentDidMount() {
    const eventId = this.props.eventId;
    const token = sessionStorage.getItem("token");
    const isOwner = sessionStorage.getItem("isOwner");
    if (isOwner && token && eventId) {
      await this.getEventDetails(eventId);
      await this.getEventScheduledStatus(eventId);
    } else {
      window.location.href = "/";
    }
  }

  /*
    Stores timeZone selected and converts the selected time slots(dates) according to timezone from 
    Previous option -> this.state.timezone ex: IST
    to 
    Current option -> e.target.value ex: PST
    Execution in steps IST -> CET
    Wed May 12 2021 09:00:00 GMT+0530 (India Standard Time) -> format("YYYY-MM-DD HH:mm")(To strip the GMT) -> 2021-05-12 09:00
    -> Time Zone conversion happens -> Wed May 12 2021 05:30:00 GMT+0200 ->format("ddd MMM DD YYYY HH:mm:ss")-> Wed May 12 2021 05:30:00 -> Wed May 12 2021 05:30:00 GMT+0530 (India Standard Time)(It is shown as IST because new Date() considers local system timezone, but the date object will be in the form of CET)
    Refer
    https://momentjs.com/timezone/
    https://momentjs.com/timezone/docs/
  */

  handleTimeZoneSelector = (e) => {
    let startDate = new Date(
      momentTimeZone
        .tz(
          this.state.eventDetails.eventStartDt,
          TIMEZONES[this.state.eventTimeZone]
        )
        .clone()
        .tz(TIMEZONES[e.target.value])
        .format("ddd MMM DD YYYY")
    );
    let startTime = momentTimeZone
      .tz(
        this.state.eventDetails.eventStartDt,
        TIMEZONES[this.state.eventTimeZone]
      )
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("HH:mm");
    let endTime = momentTimeZone
      .tz(
        this.state.eventDetails.eventEndDt,
        TIMEZONES[this.state.eventTimeZone]
      )
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("HH:mm");
    let startHours = Number(startTime.split(":")[0]);
    let startMins = Number(startTime.split(":")[1]);
    let endHours = Number(endTime.split(":")[0]);
    let endMins = Number(endTime.split(":")[1]);
    endHours = endHours === 0 ? 24 : endHours;
    if (startMins > 0) {
      startHours = (startHours * 60 + startMins) / 60;
    }
    if (endMins > 0) {
      endHours = (endHours * 60 + endMins) / 60;
    }
    let convertedEntries = {};
    for (let entry in this.state.eventDetails.entries) {
      let convertedKey = momentTimeZone
        .tz(entry, "DD-MM-YYYY_HH:mm", TIMEZONES[this.state.timezone])
        .clone()
        .tz(TIMEZONES[e.target.value])
        .format("DD-MM-YYYY_HH:mm");
      convertedEntries[convertedKey] = this.state.eventDetails.entries[entry];
    }
    this.setState({
      schedule: this.state.schedule.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      timezone: e.target.value,
      eventDetails: {
        ...this.state.eventDetails,
        entries: convertedEntries,
        startDate,
        minTime: startHours,
        maxTime: endHours,
      },
    });
  };

  /*
        This function will be called when the user clicks a block on the scheduler.
        Controlled by react-schedule-selector.
        @params {Array<Date>} newSchedule
        Restrics the owner from choosing a particular time slot, if
        1) The date is a past date
        2) If no user is available at that slot
        3) if the selected blocks are not continuous
    */

  handleScheduleChange = (newSchedule) => {
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (this.state.schedule.length < newSchedule.length) {
      const selected_date = newSchedule[newSchedule.length - 1];
      const time_diff = selected_date - newSchedule[newSchedule.length - 2];
      const formatted_date = moment(selected_date).format("DD-MM-YYYY_HH:mm");
      const count =
        this.state.eventDetails.entries[formatted_date]?.length || 0;
      if (+currentDateInTz > +selected_date) {
        alert("Past time cannot be selected");
        return;
      } else if (count === 0) {
        alert(
          "Can't schedule a meeting at this time slot since no one is available"
        );
        return;
      } else if (time_diff) {
        const time_diff_in_mins = Math.abs(time_diff / 1000 / 60);
        const time_interval =
          time_interval_in_mins[this.state.eventDetails.timeInterval];
        if (time_interval !== time_diff_in_mins) {
          alert("Slot should be selected sequentially");
          return;
        }
      }
    }
    newSchedule = newSchedule.filter((date) => currentDateInTz < date);
    this.setState({ schedule: newSchedule });
  };

  /*
        Validates the data. Checks are
        1) If the owner wants to remove the time slots after scheduling the event, click Ok in the confirm dialog
        2) If the owner is scheduling time slot for first time, check atleast one time slot is selected or not
        3) Check whether the slots are continuous
    */

  validateData = () => {
    if (this.state.isScheduled && this.state.schedule.length === 0) {
      const isDelete = window.confirm(
        "Are you sure you want to Unschedule this event?"
      );
      if (!isDelete) {
        return false;
      }
    }
    if (this.state.schedule.length === 0 && !this.state.isScheduled) {
      alert("Select a slot to schedule");
      return false;
    }
    const sorted_dates = this.state.schedule.sort((a, b) => a - b);
    const time_interval =
      time_interval_in_mins[this.state.eventDetails.timeInterval];
    for (let i = 0; i < sorted_dates.length - 1; i++) {
      const time_diff_in_mins = Math.abs(
        (sorted_dates[i + 1] - sorted_dates[i]) / 1000 / 60
      );
      if (time_interval !== time_diff_in_mins) {
        alert("Slot should be selected sequentially");
        return false;
      }
    }
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    for (let date of this.state.schedule) {
      if (date < currentDateInTz) {
        alert("Past time cannot be selected");
        return false;
      }
    }
    return true;
  };

  /*
        This function will be called when schedule button is clicked by owner.
        Validates the data and calls the API to save the data
    */

  handleScheduleSubmit = async (e) => {
    const isValid = this.validateData();
    if (!isValid) {
      return;
    }

    this.setState({ isLoading: true, isAlertOpen: false });
    const postData = {
      entries: this.state.schedule.map((date) => {
        return momentTimeZone
          .tz(
            moment(date).format("YYYY-MM-DD HH:mm"),
            TIMEZONES[this.state.timezone]
          )
          .clone()
          .tz(TIMEZONES[this.state.eventTimeZone])
          .format("DD-MM-YYYY_HH:mm");
      }),
    };

    this.scheduleEvent(postData);
  };

  /*
        This function will be called when the owner hover over a particular block.
        Used to show the available users at a particular time slot
    */

  handleMouseOver = (formatted_date) => {
    this.setState({
      selectedDate: formatted_date,
    });
  };

  /*
        This function will be called when the owner hover away from a particular block.
        The status of registered users will be rendered back to just names.No tick or X 
        if selectedDate is not cleared, it will show the status of users at the last hovered block
    */

  handleMouseOut = () => {
    this.setState({
      selectedDate: "",
    });
  };

  /*
    This function is used to render the blocks in the scheduler.
    if the date is not available in the entries,that means no user chose that time slot. So count -> 0 and color -> grey
    n = total no of registered users -> this.state.eventDetails.totalNoOfUsers
    x = total no of users available at a particular time slot -> this.state.eventDetails.entries[formatted_date].length
    if (n-2 < x) -> color -> green
    if (x > 1 && x < = n -2) -> color -> blue
    if (x == 1 or 0) color -> grey
    if the data is past date, color -> red
*/

  customDateCellRenderer = (date, selected, refSetter) => {
    const {
      eventDetails: { entries, totalNoOfUsers },
    } = this.state;
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    const formatted_date = moment(date).format("DD-MM-YYYY_HH:mm");
    const keys = Object.keys(entries);
    let color = "";
    let availablePeople = 0;
    if (keys.indexOf(formatted_date) !== -1) {
      availablePeople = entries[formatted_date].length;
      if (totalNoOfUsers - 2 < availablePeople) {
        color = "#00ff00";
      } else if (availablePeople > 1 && availablePeople <= totalNoOfUsers - 2) {
        color = "#0000ff";
      } else if (availablePeople <= 1) {
        color = "#d3d3d3";
      }
    } else {
      color = "#d3d3d3";
      availablePeople = 0;
    }
    if (currentDateInTz > date) {
      color = "#ff0000";
    }
    return (
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip">
            <div className="d-flex flex-column justify-content-start">
              {this.state.eventDetails?.userNames &&
              this.state.eventDetails.userNames.length > 0 ? (
                this.state.eventDetails?.userNames?.map((name, i) => (
                  <React.Fragment key={i}>
                    <div className="user-name">
                      <span>
                        {this.state.eventDetails.entries[formatted_date] &&
                        this.state.eventDetails.entries[formatted_date].indexOf(
                          name
                        ) !== -1
                          ? `${i + 1} ${name}`
                          : "No one available"}
                      </span>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="user-name">No one registered </div>
              )}
            </div>
          </Tooltip>
        }
      >
        <div
          style={{ backgroundColor: color }}
          className={`time-block ${selected ? "selected-indicator" : ""}`}
          ref={refSetter}
          onMouseOver={() => this.handleMouseOver(formatted_date)}
          onMouseOut={this.handleMouseOut}
          onTouchStart={() => this.handleMouseOver(formatted_date)}
          onTouchEnd={this.handleMouseOut}
        >
          <div className="available-people-indicator">{availablePeople}</div>
        </div>
      </OverlayTrigger>
    );
  };

  render() {
    const {
      eventDetails,
      userName,
      isAlertOpen,
      isLoading,
      schedule,
      timezone,
      updated_at,
    } = this.state;
    let maxTime;
    if (eventDetails.maxTime) {
      if (eventDetails.minTime >= eventDetails.maxTime)
        maxTime = Math.floor(24 + eventDetails.maxTime);
      else maxTime = eventDetails.maxTime;
    }
    let timeString = "Data not available";
    if (updated_at) {
      timeString = momentTimeZone
        .utc(
          moment(updated_at.replace("GMT"), "ddd, DD MMM YYYY HH:mm:ss").format(
            "YYYY-MM-DD HH:mm"
          )
        )
        .tz(TIMEZONES[timezone])
        .format("DD-MM-YYYY hh:mm A");
    }
    return (
      <>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <>
            <Form.Group
              controlId="formGroupScheme"
              className="timezone-selector-admin"
            >
              <Form.Label className="mr-2 font-weight-bold">
                Timezone
              </Form.Label>
              <Form.Control
                as="select"
                name="timezone"
                value={timezone}
                onChange={this.handleTimeZoneSelector}
              >
                {Object.keys(TIMEZONES).map((timezone, i) => (
                  <option value={timezone} key={i}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className="time-string">
              <span className="font-weight-bold">Last updated at : </span>
              {"  "}
              {timeString}
            </div>
            <div className="helper-text">
              Hi {userName}! Hover over a particular slot to see the available
              users at that slot
            </div>
            {isAlertOpen && (
              <Alert
                variant="info"
                className="w-50 mx-auto mt-3"
                onClose={() => this.setState({ isAlertOpen: false })}
                dismissible
              >
                <p>
                  Hey, Just to let you know, You have already scheduled this
                  event
                </p>
              </Alert>
            )}
            <div className="view-container">
              <div className="owner-schedule-selector">
                <ScheduleSelector
                  selection={schedule}
                  startDate={eventDetails.startDate}
                  numDays={eventDetails.numDays}
                  minTime={eventDetails.minTime}
                  maxTime={maxTime}
                  timeFormat="h:mm A"
                  dateFormat="MMM DD - ddd"
                  hourlyChunks={eventDetails.timeInterval}
                  onChange={this.handleScheduleChange}
                  renderDateCell={this.customDateCellRenderer}
                />
                <div className="text-center py-5">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.handleScheduleSubmit}
                  >
                    Schedule
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default AdminView;
