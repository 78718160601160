//packages
import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import moment from "moment";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import queryString from "query-string";

//helpers
import LayoutWrapper from "../../components/layoutWrapper/layoutWrapper";
import { getTimeOptions, Network } from "../../utils/helpers";
import {
  STATUS_CODES,
  API_URL_V1,
  TIMEZONES,
  emailRegex,
} from "../../utils/constants";
import "./createEvent.css";

const time_options = getTimeOptions();

class CreateEvent extends Component {
  state = {
    eventName: "Sync Up",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(6, "days").format("YYYY-MM-DD"),
    minTime: 9,
    maxTime: 17,
    timeInterval: 2,
    isLoading: false,
    name: "",
    password: "",
    email: "",
    showPassword: false,
    timezone: "IST",
  };

  /*
        Clears session storage for new event
    */

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.token) {
      localStorage.setItem("auth_token", queryParams.token);
      localStorage.setItem("user_name", queryParams.name);
      localStorage.setItem("tasks", queryParams.tasks);
      this.props.history.push(`/weekly`);
    }
    sessionStorage.clear();
  }

  /*
        Stores user's inputs in state
        If the startDate is after endDate or endDate - startDate is more than 10, 
        sets endDate value to startDate + 7 days

    */

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      if (
        moment(this.state.endDate, "YYYY-MM-DD").diff(
          moment(value, "YYYY-MM-DD"),
          "days"
        ) < 0 ||
        moment(this.state.endDate, "YYYY-MM-DD").diff(
          moment(value, "YYYY-MM-DD"),
          "days"
        ) > 10
      ) {
        this.setState({
          endDate: moment(value, "YYYY-MM-DD")
            .add(7, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
    this.setState({
      [name]: value,
    });
  };

  /*
      Validates data before submitting
      1) Name should not be empty
      2) End date cannot be before start date
      3) End time cannot be before or equal to start time
      4) Time interval cannot be empty
      5) Name cannot be empty
      6) Enter a valid email address
      7) Password should not be empty and should contain min 8 characters
      returns true if all the above criteria pass

    */

  validateData = () => {
    const {
      eventName,
      startDate,
      endDate,
      minTime,
      maxTime,
      timeInterval,
      name,
      email,
      password,
      timezone,
    } = this.state;
    if (eventName === "") {
      alert("Event name cannot be empty");
      return false;
    } else if (
      moment(endDate, "YYYY-MM-DD").diff(
        moment(startDate, "YYYY-MM-DD"),
        "days"
      ) < 0
    ) {
      alert("End date cannot be before start date");
      return false;
    } else if (Number(minTime) >= Number(maxTime)) {
      alert("End time cannot be before or equal to start time");
      return false;
    } else if (timeInterval === "") {
      alert("Time interval cannot be empty");
      return false;
    } else if (timezone === "") {
      alert("Timezone cannot be empty");
      return false;
    } else if (name === "") {
      alert("Name cannot be empty");
      return false;
    } else if (!emailRegex.test(email)) {
      alert("Enter a valid email address");
      return false;
    } else if (password === "" || password.length <= 7) {
      alert("Password should not be empty and should contain min 8 characters");
      return false;
    }
    return true;
  };

  /*
        Calls the Create Event API to create a event
        if statusCode is 201, redirects the user the sign in page, else shows alert
        postData = { event_name, start_date, end_date, start_time, end_time, time_interval, name, email, password }:Object<String>
    */

  createEvent = async (postData) => {
    const url = `${API_URL_V1}/scheduler/create_event`;
    try {
      const res = await Network(url, "POST", postData);
      this.setState({ isLoading: false });
      if (res.statusCode === STATUS_CODES.CREATED) {
        return res.event_id;
      } else {
        alert(res.status);
        return null;
      }
    } catch (err) {
      alert("Some error occured! try again or contact administrator");
      return null;
    }
  };

  /*
        When Create button is clicked, this function will validate the data 
        and if the data is valid, calls the Create Event function which in turns calls the API
    */

  submitAndCopyInvitationURL = async (e) => {
    e.preventDefault();
    const isDataValid = this.validateData();
    if (!isDataValid) {
      return;
    }
    this.setState({ isLoading: true });
    const {
      eventName,
      startDate,
      endDate,
      minTime,
      maxTime,
      timeInterval,
      name,
      email,
      password,
      timezone,
    } = this.state;
    const postData = {
      event_name: eventName,
      start_date: moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      end_date: moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      start_time: minTime,
      end_time: maxTime,
      time_interval: timeInterval,
      name: name,
      email: email,
      password: password,
      timezone: timezone,
    };
    try {
      const event_id = await this.createEvent(postData);
      if (event_id) {
        const { name } = this.state;
        const link = `${window.location.origin}/schedule/${event_id}`;
        var text = "";
        if (name !== "") {
          text = `${name} has invited you to enter your availability for an event in Immigreat Scheduler`;
        } else {
          text = `You have been invited to enter your availability for an event in Immigreat Scheduler`;
        }
        const value = `${text}.\nLink: ${link}`;
        try {
          const queryOpts = {
            name: "clipboard-write",
            allowWithoutGesture: true,
          };
          const permissionStatus =
            (await navigator.permissions.query(queryOpts)) || {};
          // Will be 'granted', 'denied' or 'prompt':
          if (permissionStatus.state === "granted") {
            await navigator.clipboard.writeText(value);
            alert("Event successfully created. Link copied to clipboard.");
          } else {
            alert(`Event successfully created, copy the link ${link}`);
          }
        } catch (err) {
          alert(`Event successfully created, copy the link ${link}`);
        }
        await this.props.history.push(`/schedule/${event_id}`);
      }
    } catch (err) {
      alert(err);
    }
  };

  /*
        This function will show and hide password based on the user's action
    */

  handleShowPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {
      eventName,
      startDate,
      endDate,
      minTime,
      maxTime,
      timeInterval,
      name,
      email,
      password,
      showPassword,
      isLoading,
      timezone,
    } = this.state;
    return (
      <LayoutWrapper>
        <div className="create-event-form">
          <Form onSubmit={this.submitAndCopyInvitationURL}>
            <Form.Group controlId="formGroupName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              <Form.Label>
                Password{" "}
                <span className="ml-2" onClick={this.handleShowPassword}>
                  {!showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </Form.Label>
              <Form.Control
                type={showPassword ? "type" : "password"}
                placeholder="Min 8 characters"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </Form.Group>
            <Form.Group controlId="formGroupEventName">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                name="eventName"
                value={eventName}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter start date"
                name="startDate"
                value={startDate}
                min={moment().format("YYYY-MM-DD")}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter end date"
                name="endDate"
                value={endDate}
                min={startDate}
                max={moment(startDate).add(9, "days").format("YYYY-MM-DD")}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formGroupStartTime">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                as="select"
                name="minTime"
                value={minTime}
                onChange={this.handleInputChange}
              >
                {time_options.map((time, i) => (
                  <React.Fragment key={i}>
                    <option value={time.value}>{time.UI_value}</option>
                  </React.Fragment>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGroupEndTIme">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                as="select"
                name="maxTime"
                value={maxTime}
                onChange={this.handleInputChange}
              >
                {time_options.map((time, i) => (
                  <React.Fragment key={i}>
                    <option value={time.value}>{time.UI_value}</option>
                  </React.Fragment>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Timezone</Form.Label>
              <Form.Control
                as="select"
                name="timezone"
                value={timezone}
                onChange={this.handleInputChange}
              >
                {Object.keys(TIMEZONES).map((timezone, i) => (
                  <option value={timezone} key={i}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label>Time Interval</Form.Label>
              <Form.Control
                as="select"
                name="timeInterval"
                value={timeInterval}
                onChange={this.handleInputChange}
              >
                <option value={1}>1 hr</option>
                <option value={2}>30 mins</option>
                <option value={3}>20 mins</option>
                <option value={4}>15 mins</option>
              </Form.Control>
            </Form.Group>
            <div className="text-center pt-3 pb-3">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? "Creating..." : "Create"}
              </Button>
            </div>
          </Form>
        </div>
      </LayoutWrapper>
    );
  }
}

export default CreateEvent;
