import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Network, showAlert } from "../../../utils/helpers";
import { STATUS_CODES, API_URL_V2 } from "../../../utils/constants";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import "./tasks.css";
import { Button, Form, Modal } from "react-bootstrap";
import moment from "moment";

const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

const colorIndicator = {
  todo: "lightskyblue",
  progress: "lightseagreen",
  review: "lightgreen",
  done: "mediumseagreen",
};

const columnsFromBackend = {
  todo: {
    name: "To do",
    items: [],
  },
  progress: {
    name: "In Progress",
    items: [],
  },
  review: {
    name: "To Be Reviewed",
    items: [],
  },
  done: {
    name: "Done",
    items: [],
  },
};

const todoInit = {
  title: "",
  description: "",
  assignee: "",
  reporter: "",
  project_vertical: "",
  priority: "medium",
  deadline: moment().format("DD-MM-YYYY"),
  status: "todo",
};

class Tasks extends React.Component {
  state = {
    columns: {},
    isLoading: true,
    showTodoModal: false,
    todo: {},
    isEditTodo: false,
    tasks: [],
    tasksFilter: "1",
  };
  setColumns = (columns) => {
    this.setState({
      columns,
    });
  };

  onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moves the tasks from one board to another board if both boards are different l
    // like todo -> progress and not todo -> todo
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      removed.status = destination.droppableId;
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      this.updateTaskStatus({
        ...removed,
        editor_id: this.props.adminId,
        editor_name: this.props.admins[this.props.adminId].split("_")[0],
        editor_email: this.props.admins[this.props.adminId].split("_")[1],
        status: destination.droppableId,
      });
    } else {
      // Moves the task up or down in the same board
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  getAllTasks = async (id, params) => {
    let url = `${API_URL_V2}/scheduler/weekly/${id}/todos?is_meeting=${params.is_meeting}&is_admin=${params.is_admin}`;
    const headers = {
      "auth-token": this.props.auth_token,
    };
    this.setState({
      isLoading: true,
    });
    let temp;
    try {
      const res = await Network(url, "GET", null, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        temp = JSON.parse(JSON.stringify(columnsFromBackend));
        for (let task of res.data) {
          temp[task.status].items.push(task);
        }
        this.setState({
          isLoading: false,
          columns: { ...temp },
          tasks: res.data,
          tasksFilter: params.is_admin,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        showAlert("error", res.status);
      }
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      showAlert("error", "Some error occured, reload page again");
    }
  };

  handleFilter = ({ target: { value } }) => {
    this.getAllTasks(this.props.adminId, {
      is_meeting: "0",
      is_admin: value,
    });
  };

  async componentDidUpdate(prev) {
    if (this.props.currentTab === "tasks" && prev.currentTab !== "tasks") {
      const { adminId } = this.props;
      await this.getAllTasks(adminId, {
        is_meeting: "0",
        is_admin: "1",
      });
    }
  }

  handleModal = (todo, isEditTodo = true) => {
    this.setState({
      isEditTodo,
      showTodoModal: true,
      todo: {
        ...todo,
        assignee: todo.assignee_id,
        reporter: todo.reporter_id,
        creator_id: todo.creator_id || todo.creator,
        deadline: moment(todo.deadline, "DD-MM-YYYY").format("YYYY-MM-DD"),
      },
    });
  };

  handleFieldChange = (e, field) => {
    this.setState({
      [field]: {
        ...this.state[field],
        [e.target.name]: e.target.value,
      },
    });
  };

  /*
    Handle modal close
  */

  handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };

  validateTodo = () => {
    const {
      title,
      description,
      assignee,
      reporter,
      priority,
      deadline,
      status,
      project_vertical,
    } = this.state.todo;
    if (
      !title.trim() ||
      !description.trim() ||
      !assignee ||
      !reporter ||
      !priority ||
      !deadline ||
      !status ||
      !project_vertical
    ) {
      showAlert("error", "Fill all the fields");
      return false;
    } else if (!moment(deadline, "YYYY-MM-DD", true).isValid()) {
      showAlert("error", "Date is invalid");
      return false;
    } else if (moment(deadline, "YYYY-MM-DD").diff(moment(), "days") < 0) {
      showAlert("error", "Task can't be created at past date");
      return false;
    }
    return true;
  };

  /*
    Validates the data and create a todo/task for a particular meeting
  */

  createTodo = async () => {
    const {
      title,
      description,
      assignee,
      reporter,
      priority,
      deadline,
      status,
      meeting_id,
      project_vertical,
    } = this.state.todo;

    const isValid = this.validateTodo();
    if (!isValid) {
      return;
    }

    const data = {
      title,
      description,
      assignee_id: assignee,
      assignee_name: this.props.admins[assignee].split("_")[0],
      assignee_email: this.props.admins[assignee].split("_")[1],
      reporter_id: reporter,
      reporter_name: this.props.admins[reporter].split("_")[0],
      reporter_email: this.props.admins[reporter].split("_")[1],
      priority,
      deadline: moment(deadline, "YYYY-MM-DD").format("DD-MM-YYYY"),
      status,
      meeting_id,
      creator_id: this.props.adminId,
      creator_name: this.props.admins[this.props.adminId].split("_")[0],
      creator_email: this.props.admins[this.props.adminId].split("_")[1],
      project_vertical,
    };
    const headers = {
      "auth-token": this.props.auth_token,
    };
    try {
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/create_todo`,
        "POST",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.CREATED) {
        showAlert("success", "Successfully created");
        await this.getAllTasks(this.props.adminId, {
          is_meeting: "0",
          is_admin: this.state.tasksFilter,
        });
      } else {
        showAlert("error", res.status);
      }
      this.handleClose("showTodoModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
      this.handleClose("showTodoModal");
    }
  };

  updateTaskStatus = async (task) => {
    try {
      const headers = {
        "auth-token": this.props.auth_token,
      };
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/edit_todo`,
        "PUT",
        task,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        //show nothing for now
      } else {
        showAlert("error", "Some error occured, could not update the task");
      }
    } catch (err) {
      showAlert("error", "Some error occured, could not update the task");
    }
  };

  /*
    Edit the todo details using API
  */

  editTodo = async () => {
    const headers = {
      "auth-token": this.props.auth_token,
    };
    try {
      const {
        title,
        description,
        assignee,
        reporter,
        priority,
        deadline,
        status,
        meeting_id,
        project_vertical,
        is_deleted,
        id,
        creator_id,
        edited_history,
      } = this.state.todo;

      const isValid = this.validateTodo();
      if (!isValid) {
        return;
      }

      const data = {
        title,
        description,
        assignee_id: assignee,
        assignee_name: this.props.admins[assignee].split("_")[0],
        assignee_email: this.props.admins[assignee].split("_")[1],
        reporter_id: reporter,
        reporter_name: this.props.admins[reporter].split("_")[0],
        reporter_email: this.props.admins[reporter].split("_")[1],
        priority,
        deadline: moment(deadline, "YYYY-MM-DD").format("DD-MM-YYYY"),
        status,
        meeting_id,
        creator_id,
        creator_name: this.props.admins[creator_id].split("_")[0],
        creator_email: this.props.admins[creator_id].split("_")[1],
        editor_id: this.props.adminId,
        editor_name: this.props.admins[this.props.adminId].split("_")[0],
        editor_email: this.props.admins[this.props.adminId].split("_")[1],
        project_vertical,
        is_deleted,
        id,
        edited_history,
      };
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/edit_todo`,
        "PUT",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully updated");
        await this.getAllTasks(this.props.adminId, {
          is_meeting: "0",
          is_admin: this.state.tasksFilter,
        });
      } else {
        showAlert("error", res.status);
      }
      this.handleClose("showTodoModal");
      this.setState({
        editTodo: false,
      });
    } catch (err) {
      this.setState({
        editTodo: false,
      });
      showAlert("error", "Some error occured, try again or contact developer");
      this.handleClose("showTodoModal");
    }
  };

  /*
    Deletes the todo/task from db using API
  */

  deleteTodo = async () => {
    const headers = {
      "auth-token": this.props.auth_token,
    };
    try {
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/edit_todo`,
        "PUT",
        { ...this.state.todo, is_deleted: true },
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully closed!");
        await this.getAllTasks(this.props.adminId, {
          is_meeting: "0",
          is_admin: this.state.tasksFilter,
        });
      } else {
        showAlert("error", res.status);
      }
      this.handleClose("showTodoModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
      this.handleClose("showTodoModal");
    }
  };

  render() {
    const { columns, isLoading, todo, showTodoModal, isEditTodo, tasksFilter } =
      this.state;
    const { admins } = this.props;
    return (
      <>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <>
            <div className="d-flex align-items-center py-4">
              <div className="mx-auto">
                <Form.Control
                  as="select"
                  value={tasksFilter}
                  onChange={this.handleFilter}
                >
                  <option value="1">My Tasks</option>
                  <option value="0">All Tasks</option>
                </Form.Control>
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.handleModal(todoInit, false)}
                >
                  Create Task
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
                paddingBottom: "5%",
                paddingTop: "3%",
              }}
            >
              <DragDropContext
                onDragEnd={(result) =>
                  this.onDragEnd(result, columns, this.setColumns)
                }
              >
                {Object.entries(columns).map(([columnId, column], index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "#f4f5f7",
                        height: "600px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                      key={columnId}
                    >
                      <h4 className="board-title">{column.name}</h4>
                      <div style={{ margin: 8 }}>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "lightblue"
                                    : "#f4f5f7",
                                  padding: 4,
                                  width: 270,
                                  minHeight: 500,
                                }}
                              >
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...provided.draggableProps.style,
                                              backgroundColor:
                                                colorIndicator[item.status],
                                            }}
                                            className="task-card"
                                            onClick={() =>
                                              this.handleModal(item)
                                            }
                                          >
                                            <div className="task-title-1">
                                              {item.title}
                                            </div>

                                            <div className="task-details">
                                              <div className="task-details-field">
                                                <div className="badge badge-secondary">
                                                  {item.deadline}
                                                </div>
                                              </div>

                                              <div className="task-details-field">
                                                <div className="badge badge-light">
                                                  {item.assignee_name}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  );
                })}
              </DragDropContext>
              <Modal
                show={showTodoModal}
                onHide={() => this.handleClose("showTodoModal")}
              >
                <Modal.Header closeButton>Task details</Modal.Header>
                <Modal.Body>
                  <div className="mt-3">
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Task Title"
                        name="title"
                        value={todo.title}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Task Description..."
                        name="description"
                        value={todo.description}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Assignee
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="assignee"
                        value={todo.assignee || ""}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      >
                        <option value="" disabled={true}>
                          Select
                        </option>
                        {Object.keys(admins).map((id, i) => (
                          <option value={id} key={i}>
                            {admins[id]}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Reporter
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="reporter"
                        value={todo.reporter || ""}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      >
                        <option value="" disabled={true}>
                          Select
                        </option>
                        {Object.keys(admins).map((id, i) => (
                          <option value={id} key={i}>
                            {admins[id]}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGroupStartTime">
                      <Form.Label className="font-weight-bold">
                        Vertical
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="project_vertical"
                        value={todo.project_vertical || ""}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      >
                        <option value="" disabled={true}>
                          Select
                        </option>
                        {this.props.teamDetails.map((team) => (
                          <option key={team.short_name} value={team.short_name}>
                            {team.short_name}
                          </option>
                        ))}
                        <option value="others">OTHERS</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Priority
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="priority"
                        value={todo.priority}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      >
                        <option value="highest">Highest</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                        <option value="lowest">Lowest</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Deadline
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Task deadline"
                        name="deadline"
                        onKeyDown={(e) => e.preventDefault()}
                        min={moment().format("YYYY-MM-DD")}
                        value={todo.deadline}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupName">
                      <Form.Label className="font-weight-bold">
                        Status
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={todo.status}
                        onChange={(e) => this.handleFieldChange(e, "todo")}
                      >
                        <option value="todo">Todo</option>
                        <option value="progress">In Progress</option>
                        <option value="review">To Be Reviewed</option>
                        <option value="done">Done</option>
                      </Form.Control>
                    </Form.Group>
                    <div
                      className={`text-center mt-2 d-flex ${
                        isEditTodo
                          ? "justify-content-around"
                          : "justify-content-center"
                      } align-items-center"`}
                    >
                      <Button
                        variant="primary"
                        onClick={isEditTodo ? this.editTodo : this.createTodo}
                      >
                        {isEditTodo ? "Update" : "Create"}
                      </Button>
                      {isEditTodo && (
                        <Button variant="danger" onClick={this.deleteTodo}>
                          Close
                        </Button>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Tasks;
