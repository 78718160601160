//packages
import React, { Component } from "react";
import { Navbar, Nav, FormControl, Button, Form } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

//helpers
import logoImg from "../../assets/images/navbar-logo.png";
import "./layoutWrapper.css";

class LayoutWrapper extends Component {
  state = {
    eventId: "",
    showSignOut: false,
  };

  /*
        Shows sign out button in Nav bar if the user is signed in
    */

  checkSignInStatus = () => {
    let { eventId } = this.props;
    let isSignedIn = sessionStorage.getItem("signedIn");
    if (eventId && isSignedIn) {
      this.setState({
        showSignOut: true,
        eventId,
      });
    }
  };

  /*
        checks sign in status to render Sign out button
    */

  componentDidMount() {
    this.checkSignInStatus();
  }

  /*
        checks sign in status to render Sign out button
    */

  componentDidUpdate() {
    if (!this.state.showSignOut) {
      this.checkSignInStatus();
    }
  }

  /*
    This function will be called when Sign out button is clicked.
    Clears the session storage and redirects the user to sign in page
    */

  handleSignOut = () => {
    sessionStorage.clear();
    window.location.href = `/schedule/${this.state.eventId}`;
  };

  /*
    Called when the users enter the event Id in search field in navbar.
    Stores the entered field data in state
    */

  handleEventIdChange = (e) => {
    this.setState({
      [e.target.name]: [e.target.value],
    });
  };

  /*
    Called when Go button in navbar is clicked
    Redirects the user to sign in page
    */

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.eventId) {
      alert("Enter Event Id");
      return;
    }
    window.location.href = `/schedule/${this.state.eventId}`;
  };

  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="md" className="navbar-container">
          <Navbar.Brand>
            <Link to="/" id="logo_dashboard_link">
              <img src={logoImg} alt="logo" className="logo-img" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavLink
                activeClassName="active-header"
                className="header-link"
                exact
                to="/"
              >
                <div className="header-button">Create Event</div>
              </NavLink>
            </Nav>
            <Form inline>
              <FormControl
                type="text"
                placeholder="Enter Event id..."
                name="eventId"
                value={this.state.eventId}
                onChange={this.handleEventIdChange}
                className="mr-sm-2"
              />
              <Button
                variant="outline-success go-btn"
                onClick={this.handleSubmit}
              >
                GO
              </Button>
            </Form>
            {this.state.showSignOut && (
              <Button
                variant="outline-danger"
                className="ml-md-5 sign-out-btn"
                onClick={this.handleSignOut}
              >
                Sign out
              </Button>
            )}
          </Navbar.Collapse>
        </Navbar>
        <main className="w-100">
          <div className="main-content">{this.props.children}</div>
        </main>
      </div>
    );
  }
}

export default LayoutWrapper;
