// packages
import React, { Component } from "react";
import ScheduleSelector from "react-schedule-selector";
import { Button, Alert, Form } from "react-bootstrap";
import styled from "styled-components";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { FaCopy } from "react-icons/fa";

//helpers
import SignIn from "../signIn/signIn";
import AdminView from "../AdminView/AdminView";
import LayoutWrapper from "../../components/layoutWrapper/layoutWrapper";
import { Network } from "../../utils/helpers";
import {
  STATUS_CODES,
  API_URL_V1,
  TIMEZONES,
  emailRegex,
} from "../../utils/constants";
import "./mainpage.css";
const DateCell = styled.div`
  width: 100%;
  height: 25px;
  background-color: ${(props) =>
    props.selected ? props.selectedColor : props.unselectedColor};
  &:hover {
    background-color: ${(props) => props.hoveredColor};
  }
`;
const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

function canShare(navigator) {
  return "share" in navigator;
}

class MainPage extends Component {
  state = {
    userName: "",
    email: "",
    eventId: "",
    showScheduler: false,
    schedule: [],
    eventDetails: {},
    isLoading: true,
    alreadySignedIn: false,
    isOwner: false,
    password: "",
    adminNames: [],
    scheduledTimeSlots: [],
    isScheduled: false,
    isAlertOpen: false,
    selectionScheme: "square",
    isSwitchApplicable: false,
    isOwnerView: false,
    timezone: "",
    eventTimeZone: "",
    updated_at: "",
  };

  /*
        Gets all Active admins name from API to show in Name field dropdown
    */

  getAdminNames = async () => {
    try {
      const url = `${API_URL_V1}/scheduler/names`;
      const res = await Network(url, "GET");
      this.setState({
        adminNames: res.data,
      });
    } catch (err) {
      alert(err);
    }
  };

  /*
        Gets event details from API to pass as paramaters to <ScheduleSelector>
    */

  getEventDetails = async (eventId, userName, email) => {
    if (!eventId) {
      this.props.history.push("/");
    }
    let url = `${API_URL_V1}/scheduler/event/${eventId}`;
    if (userName && email) {
      url = `${url}?name=${userName}&email=${email}`;
    }
    try {
      const res = await Network(url, "GET");
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        let entries = res.data.entries;
        const {
          event_name,
          end_date,
          start_date,
          start_time,
          end_time,
          time_interval,
          timezone,
          updated_at,
        } = res.data;
        entries = entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          return new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });

        let eventStartDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${
          String(start_time).length === 1 ? "0" + start_time : start_time
        }:00`;
        let eventEndDt = `${moment(start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )} ${String(end_time).length === 1 ? "0" + end_time : end_time}:00`;
        let startDate = new Date(
          momentTimeZone
            .tz(eventStartDt, TIMEZONES[timezone])
            .format("ddd MMM DD YYYY")
        );
        this.setState(
          {
            isLoading: false,
            eventTimeZone: timezone,
            updated_at,
            eventDetails: {
              eventName: event_name,
              numDays:
                moment(end_date, "DD-MM-YYYY").diff(
                  moment(start_date, "DD-MM-YYYY"),
                  "days"
                ) + 1,
              eventStartDt,
              eventEndDt,
              startDate,
              minTime: Number(start_time),
              maxTime: Number(end_time),
              timeInterval: Number(time_interval),
            },
            schedule: entries,
            eventId,
            timezone,
          },
          () => {
            if (userName && email)
              this.setState({ alreadySignedIn: true, userName, email });
          }
        );
      } else {
        alert(res.status);
        this.props.history.push("/");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      alert(error);
      this.props.history.push("/");
    }
  };

  /*
        Calls API to find whether this event is already scheduled by the owner
        to restrict the user from filling the availability
    */

  getEventScheduledStatus = async (eventId) => {
    try {
      const url = `${API_URL_V1}/scheduler/owner/event/${eventId}/entries`;
      const res = await Network(url, "GET");
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        let entries = res.data.entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          return new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });
        this.setState({
          scheduledTimeSlots: entries || [],
          isScheduled: true,
          isAlertOpen: true,
        });
      } else if (res.statusCode === STATUS_CODES.NOT_FOUND) {
        this.setState({
          scheduledTimeSlots: [],
          isAlertOpen: false,
          isScheduled: false,
        });
      }
    } catch (err) {
      alert(err);
    }
  };

  /* 
        Gets Event entries of a particular user to show it in Scheduler
    */

  getEventEntries = async (postData) => {
    try {
      const url = `${API_URL_V1}/scheduler/event/entries`;
      const res = await Network(url, "POST", postData);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        sessionStorage.setItem("name", this.state.userName);
        sessionStorage.setItem("email", this.state.email);
        sessionStorage.setItem("signedIn", true);

        let entries = res.data.entries;
        entries = entries.map((entry) => {
          const [date, time] = entry.split("_");
          const [dd, MM, yyyy] = date.split("-");
          const [hh, mm] = time.split(":");
          return new Date(+yyyy, Number(MM) - 1, +dd, +hh, +mm);
        });
        this.setState({
          schedule: entries,
          isSwitchApplicable: res.data.isOwner,
          updated_at: res.data.updated_at,
        });

        await this.getEventScheduledStatus(this.state.eventId);
        this.setState({ showScheduler: true });
      } else {
        alert(res.status);
      }
    } catch (err) {
      alert(err);
    }
    this.setState({ isLoading: false });
  };

  /*
        Authenticates the owner and redirects the owner to Admin view if the credentials are valid
    */

  loginOwner = async (postData) => {
    try {
      const url = `${API_URL_V1}/scheduler/owner/event/login`;
      const res = await Network(url, "POST", postData);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        sessionStorage.setItem("isOwner", true);
        sessionStorage.setItem("signedIn", true);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("ownerName", res.data.name);
        sessionStorage.setItem("ownerEmail", postData.email);
        this.setState({
          showScheduler: true,
          isOwnerView: true,
          isLoading: false,
          isSwitchApplicable: true,
          userName: res.data.name,
        });
      } else {
        alert(res.status);
        this.setState({ isLoading: false });
      }
    } catch (err) {
      alert(err);
      this.setState({ isLoading: false });
    }
  };

  /*
        Calls API to save user entries from Scheduler
    */

  saveUserAvailablity = async (postData) => {
    try {
      const url = `${API_URL_V1}/scheduler/event/add_entries`;
      const res = await Network(url, "POST", postData);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        this.setState({
          updated_at: res.data.updated_at,
        });
        alert("Successfully saved");
      } else {
        alert(res.status);
      }
    } catch (err) {
      alert("Some error ocurred, try again or contact administrator");
    }
    this.setState({ isLoading: false });
  };

  /*
        Gets event details and checks Event scheduled status to render the UI accordingly
    */

  async componentDidMount() {
    const eventId = this.props?.match?.params?.id || "";
    await this.getAdminNames();
    const userName = sessionStorage.getItem("name");
    const ownerName = sessionStorage.getItem("ownerName");
    const ownerEmail = sessionStorage.getItem("ownerEmail");
    const email = sessionStorage.getItem("email");
    const token = sessionStorage.getItem("token");
    const isOwner = sessionStorage.getItem("isOwner");
    if (token && isOwner) {
      this.setState({
        isSwitchApplicable: true,
      });
    }
    if (ownerName && ownerEmail) {
      this.setState({
        showScheduler: true,
        isOwnerView: true,
        isSwitchApplicable: true,
        userName: ownerName,
        email: ownerEmail,
      });
    }
    await this.getEventDetails(eventId, userName, email);
    await this.getEventScheduledStatus(eventId);
  }

  /*
        stores input field date in state
    */

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /*
    Stores timeZone selected and converts the selected time slots(dates) according to timezone from 
    Previous option -> this.state.timezone ex: IST
    to 
    Current option -> e.target.value ex: PST
    Execution in steps IST -> CET
    Wed May 12 2021 09:00:00 GMT+0530 (India Standard Time) -> format("YYYY-MM-DD HH:mm")(To strip the GMT) -> 2021-05-12 09:00
    -> Time Zone conversion happens -> Wed May 12 2021 05:30:00 GMT+0200 ->format("ddd MMM DD YYYY HH:mm:ss")-> Wed May 12 2021 05:30:00 -> Wed May 12 2021 05:30:00 GMT+0530 (India Standard Time)(It is shown as IST because new Date() considers local system timezone, but the date object will be in the form of CET)
    Refer
    https://momentjs.com/timezone/
    https://momentjs.com/timezone/docs/
  */

  handleTimeZoneSelector = (e) => {
    let convertedTime = momentTimeZone
      .tz(
        this.state.eventDetails.eventStartDt,
        TIMEZONES[this.state.eventTimeZone]
      )
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("ddd MMM DD YYYY@HH:mm");
    let startDate = new Date(convertedTime.split("@")[0]);

    let startTime = convertedTime.split("@")[1];
    let endTime = momentTimeZone
      .tz(
        this.state.eventDetails.eventEndDt,
        TIMEZONES[this.state.eventTimeZone]
      )
      .clone()
      .tz(TIMEZONES[e.target.value])
      .format("HH:mm");
    let startHours = Number(startTime.split(":")[0]);
    let startMins = Number(startTime.split(":")[1]);
    let endHours = Number(endTime.split(":")[0]);
    let endMins = Number(endTime.split(":")[1]);
    endHours = endHours === 0 ? 24 : endHours;
    if (startMins > 0) {
      startHours = (startHours * 60 + startMins) / 60;
    }
    if (endMins > 0) {
      endHours = (endHours * 60 + endMins) / 60;
    }
    this.setState({
      eventDetails: {
        ...this.state.eventDetails,
        startDate,
        minTime: startHours,
        maxTime: endHours,
      },
      schedule: this.state.schedule.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      scheduledTimeSlots: this.state.scheduledTimeSlots.map((date) => {
        return new Date(
          momentTimeZone
            .tz(
              moment(date).format("YYYY-MM-DD HH:mm"),
              TIMEZONES[this.state.timezone]
            )
            .clone()
            .tz(TIMEZONES[e.target.value])
            .format("ddd MMM DD YYYY HH:mm:ss")
        );
      }),
      timezone: e.target.value,
    });
  };
  /*
        This function will be controlled by react-schedule-selector 
        and stores the time slot selected by user in state
        @params {Array} - newSchedule = [All timeslots selected by users in standard new Date() format]
    */

  handleScheduleChange = (newSchedule) => {
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (this.state.isScheduled) {
      return;
    }
    if (this.state.schedule.length < newSchedule.length) {
      // converts current local system time to selected timezone's current time
      if (+currentDateInTz > +newSchedule[newSchedule.length - 1]) {
        alert("Past time cannot be selected");
        return;
      }
    }
    newSchedule = newSchedule.filter((date) => currentDateInTz < date);
    this.setState({ schedule: newSchedule });
  };

  /*
        Validates data for sign in
        if owner -> check email and password
        if user -> check username and email
    */

  validateData = () => {
    const { userName, email, isOwner, password } = this.state;
    if (!isOwner) {
      if (!userName || !/^[a-zA-Z\s]+$/.test(userName)) {
        alert("Enter valid name");
        return false;
      }
    } else {
      if (password === "" || password.length <= 7) {
        alert(
          "Password should not be empty and should contain atleast 8 characters"
        );
        return false;
      }
    }
    if (!emailRegex.test(email)) {
      alert("Enter valid email");
      return false;
    }
    return true;
  };

  /*
        This function will be called when sign in button is pressed
        if owner -> login owner and redirects to another page
        if user -> gets user entries for the event to render it in UI
    */

  onSubmit = async (e) => {
    e.preventDefault();
    const isValid = this.validateData();
    if (!isValid) {
      return false;
    }
    const { userName, email, eventId, password } = this.state;
    this.setState({ isLoading: true });
    if (!this.state.isOwner) {
      const postData = {
        user_name: userName,
        email: email,
        event_id: eventId,
      };

      this.getEventEntries(postData);
    } else {
      const postData = {
        email: email,
        password: password,
        event_id: eventId,
      };

      await this.loginOwner(postData);
    }
  };

  /*
        This function will be called when submit button is clicked
        Validates the data and calls the API to save the entires
    */

  handleAvailabilitySubmit = async (e) => {
    e.preventDefault();
    const { schedule, userName, email, isScheduled, eventId, eventTimeZone } =
      this.state;
    if (isScheduled) {
      alert("Event already Scheduled. Can't select a time slot now");
      return;
    }
    if (userName === "" || email === "") {
      alert("Username and email required");
      return;
    }
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    for (let date of schedule) {
      if (date < currentDateInTz) {
        alert("Past time cannot be selected");
        return;
      }
    }
    this.setState({ isLoading: true });
    // Dates will be converted from current timezone selected to timezone selected while creating event
    const postData = {
      event_id: eventId,
      entries: schedule.map((date) => {
        return momentTimeZone
          .tz(
            moment(date).format("YYYY-MM-DD HH:mm"),
            TIMEZONES[this.state.timezone]
          )
          .clone()
          .tz(TIMEZONES[eventTimeZone])
          .format("DD-MM-YYYY_HH:mm");
      }),
      user_name: userName,
      email,
    };
    await this.saveUserAvailablity(postData);
  };

  /*
        Renders the blocks in Scheduler. Controlled by react-schedule-selector
        if the date is past date, shows the block as red
        if the event is scheduled, show tick mark in that particular block
        @params {Date} date
        @params {Boolean} selected
    */

  customDateCellRenderer = (date, selected, refSetter) => {
    let color = "rgba(162, 198, 248, 1)";
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[this.state.timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (currentDateInTz > date) {
      color = "#ff0000";
    }
    let scheduled = false;
    let timeSlots = this.state.scheduledTimeSlots.map((date) => +date);
    if (timeSlots.indexOf(+date) !== -1) {
      scheduled = true;
    }

    return (
      <DateCell
        selected={selected}
        ref={refSetter}
        selectedColor={"rgba(89, 154, 242, 1)"}
        unselectedColor={color}
        hoveredColor={"#dbedff"}
        onClick={this.handleSlotClick}
      >
        <div className="d-flex justify-content-center">
          {scheduled ? "✅" : ""}
        </div>
      </DateCell>
    );
  };

  /*
        Handles Owner checkbox in sign in page.
        if owner -> show email and password form
    */

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  /*
    Renders the owner and user view based on toggle
  */

  handleSwitchView = async (e) => {
    const isOwner = sessionStorage.getItem("isOwner");
    const token = sessionStorage.getItem("token");
    const { userName, email, eventId, timezone } = this.state;
    if (!this.state.isOwnerView) {
      if (isOwner && token) {
        this.setState({
          isSwitchApplicable: e.target.value,
          isOwnerView: true,
        });
      } else {
        const password = window.prompt("Enter the event password");
        if (password) {
          const postData = {
            email: email,
            password: password,
            event_id: eventId,
          };

          await this.loginOwner(postData);
        }
      }
    } else {
      const postData = {
        user_name: userName,
        email: email,
        event_id: eventId,
        timezone: timezone,
      };
      await this.getEventEntries(postData);
      this.setState({
        isOwnerView: false,
      });
    }
  };

  copyInvitationURL = async () => {
    const { userName, eventId } = this.state;
    const link = `${window.location.origin}/schedule/${eventId}`;
    let text = "";
    if (userName !== "") {
      text = `${userName} has invited you to enter your availability for an event in Immigreat Scheduler`;
    } else {
      text = `You have been invited to enter your availability for an event in Immigreat Scheduler`;
    }
    if (canShare(navigator)) {
      try {
        await navigator.share({
          title: "Immigreat Scheduler",
          text,
          url: link,
        });
        return;
      } catch (err) {
        alert(err);
      }
    }
    const value = `${text}.\nLink: ${link}`;
    try {
      await navigator.clipboard.writeText(value);
      alert("Copied to clipboard Successfully");
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      eventDetails,
      userName,
      selectionScheme,
      eventId,
      isLoading,
      showScheduler,
      alreadySignedIn,
      schedule,
      email,
      password,
      adminNames,
      isOwner,
      isAlertOpen,
      isScheduled,
      isSwitchApplicable,
      isOwnerView,
      timezone,
      updated_at,
    } = this.state;
    let maxTime;
    if (eventDetails.maxTime) {
      if (eventDetails.minTime >= eventDetails.maxTime)
        maxTime = Math.floor(24 + eventDetails.maxTime);
      else maxTime = eventDetails.maxTime;
    }
    let timeString = "Data not available";
    if (updated_at) {
      timeString = momentTimeZone
        .utc(
          moment(
            updated_at.replace("GMT", ""),
            "ddd, DD MMM YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm")
        )
        .tz(TIMEZONES[timezone])
        .format("DD-MM-YYYY hh:mm A");
    }
    return (
      <LayoutWrapper eventId={eventId}>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <>
            <div>
              <div className="event-details-container">
                <div className="event-data event-data-container">
                  <p>
                    <span className="event-header">Event Name : </span>{" "}
                    {eventDetails.eventName}
                  </p>
                  {(showScheduler || alreadySignedIn) && !isOwnerView && (
                    <p>
                      <span className="event-header">Last updated at : </span>{" "}
                      {timeString}
                    </p>
                  )}
                  <span
                    className="cursor-pointer"
                    onClick={this.copyInvitationURL}
                  >
                    <FaCopy /> Copy event link
                  </span>
                </div>
                {isSwitchApplicable && (showScheduler || alreadySignedIn) && (
                  <div className="text-center mb-3">
                    <input
                      type="checkbox"
                      name="isOwnerView"
                      onChange={this.handleSwitchView}
                      checked={isOwnerView}
                      id="owner-view-toggle"
                    />
                    <label htmlFor="owner-view-toggle" className="ml-2">
                      Owner View
                    </label>
                  </div>
                )}
              </div>
            </div>
            {showScheduler || alreadySignedIn ? (
              !isOwnerView ? (
                <div>
                  {isAlertOpen ? (
                    <Alert
                      variant="danger"
                      className="w-50 mx-auto mt-3"
                      onClose={() => this.setState({ isAlertOpen: false })}
                      dismissible
                    >
                      <p>
                        Hi{" "}
                        {userName.charAt(0).toUpperCase() + userName.slice(1)},
                        This event has been already scheduled ✅. So you can't
                        fill your availablity now
                      </p>
                    </Alert>
                  ) : (
                    <div className="helper-text">
                      Hi {userName.charAt(0).toUpperCase() + userName.slice(1)}{" "}
                      ! Click or Drag below to fill your availablity
                    </div>
                  )}
                  <div className="schedule-selector">
                    <Form.Group
                      controlId="formGroupScheme"
                      className="selection-scheme mx-auto"
                    >
                      <Form.Label>Selection Scheme</Form.Label>
                      <Form.Control
                        as="select"
                        name="selectionScheme"
                        value={selectionScheme}
                        onChange={this.handleInputChange}
                      >
                        <option value="linear">Linear</option>
                        <option value="square">Square</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="formGroupScheme"
                      className="selection-scheme mx-auto"
                    >
                      <Form.Label>Timezone</Form.Label>
                      <Form.Control
                        as="select"
                        name="timezone"
                        value={timezone}
                        onChange={this.handleTimeZoneSelector}
                      >
                        {Object.keys(TIMEZONES).map((timezone, i) => (
                          <option value={timezone} key={i}>
                            {timezone}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <ScheduleSelector
                      selection={schedule}
                      startDate={eventDetails.startDate}
                      numDays={eventDetails.numDays}
                      minTime={eventDetails.minTime}
                      maxTime={maxTime}
                      timeFormat="h:mm A"
                      dateFormat="MMM DD - ddd"
                      hourlyChunks={eventDetails.timeInterval}
                      onChange={this.handleScheduleChange}
                      renderDateCell={this.customDateCellRenderer}
                      selectionScheme={selectionScheme}
                    />
                    <div className="text-center py-5">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={this.handleAvailabilitySubmit}
                        disabled={isScheduled}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <AdminView eventId={eventId} />
              )
            ) : (
              <div className="signin-form">
                <div className="text-center mb-3">
                  <input
                    type="checkbox"
                    name="isOwner"
                    onChange={this.handleCheckBox}
                    checked={isOwner}
                    id="customSwitch1"
                  />
                  <label htmlFor="customSwitch1" className="ml-2">
                    Owner
                  </label>
                </div>
                <SignIn
                  userName={userName}
                  email={email}
                  password={password}
                  adminNames={adminNames}
                  handleInputChange={this.handleInputChange}
                  onSubmit={this.onSubmit}
                  isOwner={isOwner}
                />
              </div>
            )}
          </>
        )}
      </LayoutWrapper>
    );
  }
}

export default MainPage;
