import moment from "moment";
import momentTimeZone from "moment-timezone";

//const BASE_URL = "http://127.0.0.1:8000";
const BASE_URL = "https://console-dot-immigreat-qa.el.r.appspot.com";
const API_URL_V1 = `${BASE_URL}/api/v1`;
const API_URL_V2 = `${BASE_URL}/api/v2`;

const STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  NOT_AUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};

//https://momentjs.com/timezone/docs/#/using-timezones/getting-zone-names/
// Add timezones supported by moment here
const TIMEZONES = {
  IST: "Asia/Kolkata",
  PST: "America/Los_Angeles",
  CET: "Europe/Paris",
  EST: "America/New_York",
  SGT: "Asia/Singapore",
  BST: "Europe/London",
};

const start_date_current = momentTimeZone
  .tz(moment().format("YYYY-MM-DD HH:mm"), momentTimeZone.tz.guess(true))
  .clone()
  .tz(TIMEZONES["IST"])
  .startOf("isoWeek")
  .format("DD-MM-YYYY");
const start_date_next = moment(start_date_current, "DD-MM-YYYY")
  .add(7, "days")
  .format("DD-MM-YYYY");
const end_date_current = momentTimeZone
  .tz(moment().format("YYYY-MM-DD HH:mm"), momentTimeZone.tz.guess(true))
  .clone()
  .tz(TIMEZONES["IST"])
  .endOf("isoWeek")
  .format("DD-MM-YYYY");
const end_date_next = moment(end_date_current, "DD-MM-YYYY")
  .add(7, "days")
  .format("DD-MM-YYYY");

const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
  start_date_current,
  start_date_next,
  end_date_current,
  end_date_next,
  BASE_URL,
  API_URL_V1,
  API_URL_V2,
  TIMEZONES,
  STATUS_CODES,
  emailRegex,
};
