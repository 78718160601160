import { toast } from "react-toastify";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import {
  start_date_current,
  start_date_next,
  end_date_current,
  end_date_next,
  TIMEZONES,
} from "./constants";

const getTimeOptions = () => {
  const time_list = [{ value: 0, UI_value: "12:00 AM" }];
  let identifier = "AM";
  let flag = 0;
  let orig = 1;
  for (let i = 1; i <= 12; i++) {
    let time = {
      value: orig,
      UI_value: String(i) + ":00 ",
    };
    orig += 1;
    if (i === 12 && flag === 0) {
      identifier = "PM";
      i = 0;
      flag = 1;
    } else if (i === 12 && flag === 1) {
      identifier = "AM";
    }
    time.UI_value += identifier;
    time_list.push(time);
  }
  return time_list;
};

const Network = async (url, method, body = null, headers = {}) => {
  const reqBody = body ? JSON.stringify(body) : null;

  const fetchParams = { method, headers };
  if ((method === "POST" || method === "PUT") && !reqBody) {
    throw new Error("Request body required");
  }

  if (reqBody) {
    fetchParams.headers["Content-type"] = "application/json";
    fetchParams.body = reqBody;
  }
  return fetch(url, fetchParams).then(async (res) => {
    let data = await res.json();
    data.statusCode = res.status;
    return data;
  });
};

const showAlert = (type, msg) => {
  toast[type](msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const getFormattedEntries = (entries, timezone, eventTimeZone) => {
  entries.sort((a, b) => a - b);
  let formattedEntries = {};
  let entry = momentTimeZone
    .tz(moment(entries[0]).format("YYYY-MM-DD HH:mm"), TIMEZONES[timezone])
    .clone()
    .tz(TIMEZONES[eventTimeZone])
    .format("DD-MM-YYYY_HH:mm");
  const [date, time] = entry.split("_");
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  formattedEntries[date] = formattedEntries[date] || [];
  formattedEntries[date].push({
    min_hrs: hours,
    max_hrs: hours,
    min_mins: minutes,
    max_mins: minutes,
  });
  if (entries.length > 1) {
    for (let i = 0; i < entries.length - 1; i++) {
      let entry_1 = momentTimeZone
        .tz(moment(entries[i]).format("YYYY-MM-DD HH:mm"), TIMEZONES[timezone])
        .clone()
        .tz(TIMEZONES[eventTimeZone])
        .format("DD-MM-YYYY_HH:mm");
      let entry_2 = momentTimeZone
        .tz(
          moment(entries[i + 1]).format("YYYY-MM-DD HH:mm"),
          TIMEZONES[timezone]
        )
        .clone()
        .tz(TIMEZONES[eventTimeZone])
        .format("DD-MM-YYYY_HH:mm");
      const [date, time] = entry_2.split("_");
      let [hours, minutes] = time.split(":");
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      if (entry_1.split("_")[0] === entry_2.split("_")[0]) {
        let index = formattedEntries[date].length - 1;
        if (
          moment(entry_2, "DD-MM-YYYY_HH:mm").diff(
            moment(entry_1, "DD-MM-YYYY_HH:mm"),
            "minutes"
          ) === 30
        ) {
          if (formattedEntries[date][index].min_hrs >= hours) {
            formattedEntries[date][index].min_hrs = hours;
            if (formattedEntries[date][index].min_mins >= minutes)
              formattedEntries[date][index].min_mins = minutes;
          }
          if (formattedEntries[date][index].max_hrs <= hours) {
            formattedEntries[date][index].max_hrs = hours;
            formattedEntries[date][index].max_mins = minutes;
          }
        } else {
          formattedEntries[date][index + 1] = {
            min_hrs: hours,
            max_hrs: hours,
            min_mins: minutes,
            max_mins: minutes,
          };
        }
      } else {
        formattedEntries[date] = formattedEntries[date] || [];
        formattedEntries[date].push({
          min_hrs: hours,
          max_hrs: hours,
          min_mins: minutes,
          max_mins: minutes,
        });
      }
    }
  }
  return formattedEntries;
};

const getExpandedEntries = (formattedEntries) => {
  let final = [];
  let temp;
  for (let [key, entries] of Object.entries(formattedEntries)) {
    for (let { min_hrs, max_hrs, min_mins, max_mins } of entries) {
      temp = [];
      for (let hr = min_hrs; hr <= max_hrs; hr++) {
        temp.push(
          `${key}_${String(hr).padStart(2, "0")}:00`,
          `${key}_${String(hr).padStart(2, "0")}:30`
        );
      }
      if (temp.length > 1) {
        if (min_mins === 30) {
          temp.shift();
        }
        if (max_mins === 0) {
          temp.pop();
        }
      }
      final.push(...temp);
    }
  }
  return [...new Set(final)];
};

const getStartDateByWeek = (week) =>
  week === "current" ? start_date_current : start_date_next;
const getEndDateByWeek = (week) =>
  week === "current" ? end_date_current : end_date_next;

export {
  getTimeOptions,
  Network,
  showAlert,
  getFormattedEntries,
  getExpandedEntries,
  getStartDateByWeek,
  getEndDateByWeek,
};
