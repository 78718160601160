import React, { Component } from "react";
import { Button, Form, Modal, Tabs, Tab } from "react-bootstrap";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import Select from "react-select/creatable";
import { uniqBy } from "lodash";
import RichTextEditor from "react-rte";
import { FaCopy } from "react-icons/fa";
import { MdAddCircle, MdOutlineRemoveCircle } from "react-icons/md";

//helpers
import { Network, showAlert } from "../../../utils/helpers";
import { STATUS_CODES, API_URL_V2, TIMEZONES } from "../../../utils/constants";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../myMeetings/myMeetings.css";
import "./newMeetings.css";

const override = css`
  display: block;
  margin: auto;
  margin-top: 5%;
  border-color: red;
`;

const localizer = momentLocalizer(moment);

function CustomEvent({ event }) {
  return (
    <span className="event-details">
      <strong>
        {moment(event.start).format("hh:mm A")} -
        {moment(event.end).format("hh:mm A")} {event.title}
      </strong>
    </span>
  );
}

const todoStatus = {
  todo: "To Do",
  progress: "In Progress",
  review: "To Be Reviewed",
  done: "Done",
};

class MyMeetings extends Component {
  /*
    Find start and end date of a current week and next week
  */
  start_date_current = momentTimeZone
    .tz(moment().format("YYYY-MM-DD HH:mm"), momentTimeZone.tz.guess(true))
    .clone()
    .tz(TIMEZONES["IST"])
    .startOf("isoWeek")
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  start_date_next = moment(this.start_date_current, "YYYY-MM-DD")
    .add(7, "days")
    .format("YYYY-MM-DD");
  end_date_current = momentTimeZone
    .tz(moment().format("YYYY-MM-DD HH:mm"), momentTimeZone.tz.guess(true))
    .clone()
    .tz(TIMEZONES["IST"])
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  end_date_next = moment(this.end_date_current, "YYYY-MM-DD")
    .add(7, "days")
    .format("YYYY-MM-DD");
  auth_token = "";
  state = {
    isLoading: true,
    week_id: "",
    adminId: "",
    filterBy: "1",
    timezone: "IST",
    showModal: false,
    meeting: {},
    events: [],
    isSpecialAdmin: false,
    showConfirmModal: false,
    start: "",
    end: "",
    email: "",
    showEditModal: false,
    editedEvent: {
      formattedDescription: [],
    },
    showDeleteModal: false,
    deleteEventMainId: "",
    deleteEventId: "",
    deleteAll: false,
    todo: {
      title: "",
      description: "",
      assignee: "",
      reporter: "",
      project_vertical: "",
      priority: "medium",
      deadline: moment().format("YYYY-MM-DD"),
      status: "todo",
    },
    showTodoModal: false,
    tabKey: "details",
    todos: [],
    isEditTodo: false,
    meetingMinutes: {
      title: "",
      text: RichTextEditor.createEmptyValue(),
    },
    minutesForMeeting: [],
    createTabKey: "todo",
    adminNames: {},
  };

  /*
    Changes start time and end time based on the timezone selected
  */

  handleStoreEvents = (meeting, arr) => {
    arr.push({
      ...meeting,
      title: meeting.summary,
      desc: meeting.description,
      start: new Date(
        momentTimeZone
          .tz(
            moment(meeting.start.dateTime, "YYYY-MM-DDTHH:mm:ssZ").format(
              "YYYY-MM-DD HH:mm"
            ),
            "YYYY-MM-DD HH:mm",
            meeting.start.timeZone
          )
          .clone()
          .tz(TIMEZONES[this.state.timezone])
          .format("YYYY-MM-DD HH:mm")
      ),
      end: new Date(
        momentTimeZone
          .tz(
            moment(meeting.end.dateTime, "YYYY-MM-DDTHH:mm:ssZ").format(
              "YYYY-MM-DD HH:mm"
            ),
            "YYYY-MM-DD HH:mm",
            meeting.end.timeZone
          )
          .clone()
          .tz(TIMEZONES[this.state.timezone])
          .format("YYYY-MM-DD HH:mm")
      ),
    });
  };

  /*
    Fetch meetings using API and filter them based on the filter selected
  */

  getMyMeetings = async (selectedOption = "1") => {
    try {
      if (!this.auth_token) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
        this.props.history.push("/");
      }
      const adminId = this.auth_token.split("@")[0];
      const url = `${API_URL_V2}/scheduler/weekly/meetings`;
      const headers = {
        "auth-token": this.auth_token,
      };
      this.setState({
        isLoading: true,
      });
      const postData = {
        is_my_meetings: selectedOption === "1",
      };
      const res = await Network(url, "POST", postData, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        let filteredMeetings = [];
        for (let meeting of res.data) {
          this.handleStoreEvents(meeting, filteredMeetings);
        }

        this.setState({
          events: filteredMeetings,
          adminId,
          filterBy: selectedOption,
          isSpecialAdmin: res.is_special_admin,
          email: res.email,
        });
      } else if (res.statusCode === STATUS_CODES.NOT_AUTHORIZED) {
        showAlert(
          "error",
          "Auth token expired or not valid, login from admin console again"
        );
        this.props.history.push("/");
      } else {
        showAlert("error", res.status);
      }
    } catch (err) {
      showAlert("error", err);
    }
    this.setState({ isLoading: false });
  };

  /*
    Fetches meetings,admins,projects on page load
  */

  componentDidMount() {
    this.auth_token = localStorage.getItem("auth_token");
    if (!this.auth_token) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prev) {
    if (
      this.props.currentTab === "meetings" &&
      prev.currentTab !== "meetings"
    ) {
      this.setState(
        {
          timezone: "IST",
        },
        async () => {
          await this.getMyMeetings();
          this.scrollToBottom();
        }
      );
    }
  }

  /*
    Formats entries based on the timezone selected
  */

  handleTimeZoneSelector = (e) => {
    let events = [];
    for (let event of this.state.events) {
      event["start"] = new Date(
        momentTimeZone
          .tz(
            moment(event.start).format("YYYY-MM-DD HH:mm"),
            TIMEZONES[this.state.timezone]
          )
          .clone()
          .tz(TIMEZONES[e.target.value])
          .format("YYYY-MM-DD HH:mm")
      );
      event["end"] = new Date(
        momentTimeZone
          .tz(
            moment(event.end).format("YYYY-MM-DD HH:mm"),
            TIMEZONES[this.state.timezone]
          )
          .clone()
          .tz(TIMEZONES[e.target.value])
          .format("YYYY-MM-DD HH:mm")
      );

      events.push(event);
    }
    this.setState({
      timezone: e.target.value,
      events,
    });
  };

  /*
    Handle modal close
  */

  handleClose = (name) => {
    if (name === "showTodoModal" || name === "showModal") {
      this.setState({
        todo: {},
        isEditTodo: false,
        todos: [],
        tabKey: "details",
        createTabKey: "todo",
        meetingMinutes: {
          title: "",
          text: RichTextEditor.createEmptyValue(),
        },
        meeting: {},
        [name]: false,
      });
    } else {
      this.setState({
        [name]: false,
      });
    }
  };

  /*
    Handles modal open
  */

  handleShowModal = (meeting) => {
    this.setState({
      meeting,
      showModal: true,
      editedEvent: {
        formattedDescription: [],
      },
    });
  };

  /*
    When a user selects a slot which there is no meeting, 
    user can create a meeting at that slot by clicking yes in the modal
    will be redirected to teams page
  */

  handleSelect = () => {
    const { start, end, timezone } = this.state;
    const currentDateInTz = new Date(
      momentTimeZone()
        .tz(TIMEZONES[timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    if (currentDateInTz > end || currentDateInTz > start) {
      showAlert("error", "Cannot schedule a meeting at past time");
      return;
    }

    this.handleClose("showConfirmModal");
    this.props.handleSwitchView("team", {
      start,
      end,
      timezone,
    });
  };

  /*
    Gets meetings for current week or next week based on the filter selected
  */

  handleFilter = async ({ target: { value } }) => {
    this.setState({
      isLoading: true,
    });
    await this.getMyMeetings(value);

    this.setState({
      isLoading: false,
    });
  };

  /*
    Shows edit modal with meeting details
  */

  handleBtnEditClick = () => {
    let selectedAttendees = [];

    this.state.meeting.attendees.forEach((attendee) => {
      if (attendee.displayName) {
        selectedAttendees.push({
          value: attendee.email,
          label: `${attendee.displayName}_${attendee.email}`,
        });
      } else if (attendee.email in this.props.adminNames) {
        selectedAttendees.push({
          value: attendee.email,
          label: `${this.props.adminNames[attendee.email]}_${attendee.email}`,
        });
      } else {
        selectedAttendees.push({
          value: attendee.email,
          label: `${attendee.email.split("@")[0]}_${attendee.email}`,
        });
      }
    });
    let description = this.state.meeting.description.split("\n");
    let formattedDescription = [];
    for (let desc of description) {
      if (desc.trim().includes("Meeting Agenda") || desc.trim() === "") {
        continue;
      }
      let val = desc.split(") ")[1];
      formattedDescription.push({
        val: val || desc,
      });
    }
    if (formattedDescription.length === 0) {
      formattedDescription.push({
        val: this.state.meeting.description,
      });
    }
    this.setState({
      showEditModal: true,
      editedEvent: {
        ...this.state.meeting,
        formattedDescription,
        eligibleAttendees: Object.keys(this.props.admins).map((id) => ({
          value: id,
          label: this.props.admins[id],
        })),
        selectedAttendees,
        isAll: false,
        start: moment(this.state.meeting.start, "YYYY-MM-DD HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
        end: moment(this.state.meeting.end, "YYYY-MM-DD HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
      },
      showModal: false,
    });
  };

  /*
    Validates the data and update the meeting
  */

  handleEditConfirm = async () => {
    const { summary, start, end } = this.state.editedEvent;
    const data = { ...this.state.editedEvent };
    if (!summary || !start || !end) {
      showAlert("error", "Please fill all the fields correctly");
      return;
    } else if (
      !moment(start, "YYYY-MM-DDTHH:mm", true).isValid() ||
      !moment(end, "YYYY-MM-DDTHH:mm", true).isValid()
    ) {
      showAlert("error", "From or to date is invalid");
      return;
    } else if (moment(start, "YYYY-MM-DDTHH:mm").diff(moment(), "hours") < 0) {
      showAlert("error", "From date cannot be past date");
      return;
    } else if (moment(end, "YYYY-MM-DDTHH:mm").diff(moment(), "hours") < 0) {
      showAlert("error", "To date cannot be past date");
      return;
    }

    data["start"] = {
      dateTime: moment(data["start"], "YYYY-MM-DDTHH:mm").format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
      timeZone: "Asia/Kolkata",
    };
    data["end"] = {
      dateTime: moment(data["end"], "YYYY-MM-DDTHH:mm").format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
      timeZone: "Asia/Kolkata",
    };
    delete data["desc"];
    delete data["title"];
    delete data["eligibleAttendees"];
    data["attendees"] = [];
    for (let email of data["selectedAttendees"]) {
      data["attendees"].push({
        displayName: email.label.split("_")[0],
        email: email.label.split("_")[1],
        responseStatus: "needsAction",
      });
    }
    let description = "Meeting Agenda: \n";
    let { formattedDescription } = this.state.editedEvent;
    for (let i = 1; i <= formattedDescription.length; i++) {
      description += `${i}) ${formattedDescription[i - 1].val} \n`;
    }
    if (
      formattedDescription.length === 1 &&
      formattedDescription[0].val.trim() === ""
    ) {
      description += "Not available ";
    }
    data["description"] = description;
    delete data["selectedAttendees"];
    delete data["formattedDescription"];
    if (data["isAll"]) {
      data["until"] = moment(data["start"]["dateTime"], "YYYY-MM-DDTHH:mm:ssZ")
        .subtract(1, "days")
        .format("YYYYMMDD");
    }
    data["attendees"] = uniqBy(data["attendees"], "email");
    const url = `${API_URL_V2}/scheduler/weekly/meeting/edit`;
    const headers = {
      "auth-token": this.auth_token,
    };
    try {
      const res = await Network(url, "POST", data, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Succesfully updated");
        this.handleClose("showEditModal");
        this.getMyMeetings();
      } else {
        this.handleClose("showEditModal");
        showAlert("error", res.status);
      }
    } catch (err) {
      showAlert("error", err);
    }
  };

  /*
    handles edit event meeting details field changes
  */

  handleFieldChange = (e, field) => {
    this.setState({
      [field]: {
        ...this.state[field],
        [e.target.name]: e.target.value,
      },
    });
  };

  /*
    handles select attendees field change
  */

  handleChange = (selectedOption) => {
    this.setState({
      editedEvent: {
        ...this.state.editedEvent,
        selectedAttendees: selectedOption,
      },
    });
  };

  /*
    Handles change all recurring meeting checkbox field change
  */

  handleCheckBox = (e) => {
    this.setState({
      editedEvent: {
        ...this.state.editedEvent,
        isAll: !this.state.editedEvent.isAll,
      },
    });
  };

  /*
    Handles delete all recurring meetings checkbox field
  */

  handleDeleteAll = (e) => {
    this.setState({
      deleteAll: !this.state.deleteAll,
    });
  };

  /*
    Validates the data and deletes the meeting
  */

  handleDelete = async () => {
    this.handleClose("showDeleteModal");
    const { deleteEventMainId, deleteEventId, deleteAll } = this.state;
    const id = deleteAll ? deleteEventMainId : deleteEventId;
    if (!id) {
      showAlert(
        "error",
        "This meeting could not be deleted, contact developer"
      );
      return;
    }
    const url = `${API_URL_V2}/scheduler/weekly/meeting/delete/${id}`;
    const headers = {
      "auth-token": this.auth_token,
    };
    this.setState({
      deleteEventId: "",
      deleteEventMainId: "",
      deleteAll: false,
    });
    try {
      const res = await Network(url, "DELETE", null, headers);
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully deleted");
        this.getMyMeetings();
      }
    } catch (err) {
      showAlert("error", err);
    }
  };

  /*
    Shows the modal on delete btn click
  */

  handleDeleteModal = (mainId, id) => {
    this.handleClose("showModal");
    this.setState({
      deleteEventId: id,
      deleteEventMainId: mainId,
      showDeleteModal: true,
    });
  };

  validateTodo = () => {
    const {
      title,
      description,
      assignee,
      reporter,
      priority,
      deadline,
      status,
      project_vertical,
    } = this.state.todo;
    if (
      !title.trim() ||
      !description.trim() ||
      !assignee ||
      !reporter ||
      !priority ||
      !deadline ||
      !status ||
      !project_vertical
    ) {
      showAlert("error", "Fill all the fields");
      return false;
    } else if (!moment(deadline, "YYYY-MM-DD", true).isValid()) {
      showAlert("error", "Date is invalid");
      return false;
    } else if (moment(deadline, "YYYY-MM-DD").diff(moment(), "days") < 0) {
      showAlert("error", "Task can't be created at past date");
      return false;
    }
    return true;
  };

  /*
    Validates the data and create a todo/task for a particular meeting
  */

  createTodo = async () => {
    const {
      title,
      description,
      assignee,
      reporter,
      priority,
      deadline,
      status,
      meeting_id,
      project_vertical,
    } = this.state.todo;

    const isValid = this.validateTodo();
    if (!isValid) {
      return;
    }

    const data = {
      title,
      description,
      assignee_id: assignee,
      assignee_name: this.props.admins[assignee].split("_")[0],
      assignee_email: this.props.admins[assignee].split("_")[1],
      reporter_id: reporter,
      reporter_name: this.props.admins[reporter].split("_")[0],
      reporter_email: this.props.admins[reporter].split("_")[1],
      priority,
      deadline: moment(deadline, "YYYY-MM-DD").format("DD-MM-YYYY"),
      status,
      meeting_id,
      creator_id: this.state.adminId,
      creator_name: this.props.admins[this.state.adminId].split("_")[0],
      creator_email: this.props.admins[this.state.adminId].split("_")[1],
      project_vertical,
    };
    const headers = {
      "auth-token": this.auth_token,
    };
    try {
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/create_todo`,
        "POST",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.CREATED) {
        showAlert("success", "Successfully created");
      } else {
        showAlert("error", res.status);
      }
      this.handleClose("showTodoModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
      this.handleClose("showTodoModal");
    }
  };

  /*
    Shows modal for creating todo/task 
  */

  handleTodoModal = (id) => {
    this.setState({
      showTodoModal: true,
      showModal: false,
      todo: {
        ...this.state.todo,
        meeting_id: id,
        reporter: this.state.adminId,
        priority: "medium",
        deadline: moment().format("YYYY-MM-DD"),
        status: "todo",
        project_vertical: "",
      },
    });
  };

  /*
    Toggles tab for creating todo/MOM
    Gets sample MOM template from API and prepopulate the data we have dynamically and modify the html
    and renders it using RichTextEditor plugin
  */

  handleCreateTabChange = async (key) => {
    this.setState({
      createTabKey: key,
    });
    if (key === "mm") {
      if (!this.state.meeting.is_meeting_minutes) {
        try {
          const headers = {
            "auth-token": this.auth_token,
          };
          const res = await Network(
            `${API_URL_V2}/scheduler/weekly/mm/sample`,
            "GET",
            null,
            headers
          );
          if (res.statusCode === STATUS_CODES.SUCCESS) {
            if (Object.keys(res.data).length !== 0) {
              await this.handleTabChange("todo", false);
              let meetingInfo = {
                absentees: [],
                participants: {},
              };
              const url = `${API_URL_V2}/scheduler/weekly/meeting/info`;
              const headers = {
                "auth-token": this.auth_token,
              };

              try {
                const res = await Network(
                  url,
                  "POST",
                  {
                    date: moment(
                      this.state.meeting.start,
                      "YYYY-MM-DD HH:mm"
                    ).format("YYYY-MM-DD"),
                    meeting_id: this.state.meeting.id,
                  },
                  headers
                );
                if (res.statusCode === STATUS_CODES.SUCCESS) {
                  meetingInfo = res.data;
                } else {
                  showAlert("error", res.status);
                }
              } catch (err) {
                showAlert("error", err);
              }
              let formattedText = res.data.text;
              formattedText = formattedText
                .replace("[meeting title]", this.state.meeting.title)
                .replace(
                  "[DATE]",
                  moment(this.state.meeting.start, "YYYY-MM-DD HH:mm").format(
                    "DD-MM-YYYY"
                  )
                )
                .replace(
                  "[START]",
                  moment(this.state.meeting.start, "YYYY-MM-DD HH:mm").format(
                    "hh:mm A"
                  )
                )
                .replace(
                  "[END]",
                  moment(this.state.meeting.end, "YYYY-MM-DD HH:mm").format(
                    "hh:mm A"
                  )
                );
              let attendeesHtml = "<ul>";
              let tasksHtml = "<ol>";
              let agendaHtml = "<ol>";
              for (let attendee of this.state.meeting.attendees) {
                if (meetingInfo.absentees.includes(attendee.email)) {
                  continue;
                }
                attendeesHtml += `<li>${attendee.email} ${
                  Object.keys(meetingInfo.participants).length > 0
                    ? "<i>(Time spent: " +
                      Math.floor(
                        meetingInfo.participants[attendee.email] / 60
                      ) +
                      "m )</i>"
                    : ""
                }</li>`;
              }
              for (let task of this.state.todos) {
                tasksHtml += `<li>${task.creator_name} assigned <b>${task.description}</b> to ${task.assignee_name} and the deadline is ${task.deadline} and the priority is ${task.priority}</li>`;
              }
              let description = (
                this.state.meeting.description || "Not Available"
              ).split("\n");

              for (let desc of description) {
                if (
                  desc.trim().includes("Meeting Agenda") ||
                  desc.trim() === ""
                ) {
                  continue;
                }
                let val = desc.split(") ")?.[1];
                agendaHtml += `<li>${val || desc}<br><span>[NOTES]</span></li>`;
              }
              if (agendaHtml === "<ol>") {
                agendaHtml += `<li>${
                  this.state.meeting.description || "Not available"
                }<br><p>[NOTES]</p></li>`;
              }
              agendaHtml += "</ol>";
              if (tasksHtml === "<ol>") {
                tasksHtml += "<li>No tasks created for this meeting</li>";
              }
              attendeesHtml += "</ul>\n<p><strong>Absentees :</strong></p><ul>";
              for (let absentee of meetingInfo.absentees) {
                attendeesHtml += `<li>${this.props.adminNames[absentee]}</li>`;
              }
              if (meetingInfo.absentees.length === 0) {
                attendeesHtml += "<li>No absentees/No data found</li>";
              }
              attendeesHtml += "</ul>";
              tasksHtml += "</ol>";
              formattedText = formattedText
                .replace("<div>[PARTICIPANTS]</div>", attendeesHtml)
                .replace("<div>[TASKS]</div>", tasksHtml)
                .replace("<div>[AGENDA]</div>", agendaHtml)
                .replace("<p>[BODY / SUMMARY (list of points)]</p>", "");
              this.setState({
                meetingMinutes: {
                  title: res.data.title
                    .replace("<meeting title>", this.state.meeting.title)
                    .replace(
                      "<date>",
                      moment(
                        this.state.meeting.start,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD-MM-YYYY")
                    ),
                  text: RichTextEditor.createValueFromString(
                    formattedText,
                    "html"
                  ),
                },
              });
            }
          } else {
            showAlert("error", res.status);
          }
        } catch (err) {
          showAlert(
            "error",
            "Some error occured, try again or contact developer"
          );
        }
      }
    }
  };

  /*
    Toggles the tab between meeting details, tasks and MOM
    Fetches tasks and MOM from API
  */

  handleTabChange = async (key, isChange = true) => {
    if (isChange) {
      this.setState({
        tabKey: key,
      });
    }
    if (key === "todo") {
      try {
        const headers = {
          "auth-token": this.auth_token,
        };
        const res = await Network(
          `${API_URL_V2}/scheduler/weekly/${this.state.meeting.id}/todos?is_meeting=1`,
          "GET",
          null,
          headers
        );
        if (res.statusCode === STATUS_CODES.SUCCESS) {
          this.setState({
            todos: res.data,
          });
        } else {
          showAlert("error", res.status);
        }
      } catch (err) {
        showAlert(
          "error",
          "Some error occured, try again or contact developer"
        );
      }
    } else if (key === "mm") {
      try {
        const headers = {
          "auth-token": this.auth_token,
        };
        const res = await Network(
          `${API_URL_V2}/scheduler/weekly/mm/${this.state.meeting.id}`,
          "GET",
          null,
          headers
        );
        if (res.statusCode === STATUS_CODES.SUCCESS) {
          if (Object.keys(res.data).length !== 0) {
            let formattedText = res.data.text;
            let index = formattedText.indexOf("Action Items:");
            formattedText = formattedText.slice(0, index + 13);
            formattedText += "</strong></p>\n";
            let tasksHtml = "<ol>";
            await this.handleTabChange("todo", false);
            for (let task of this.state.todos) {
              tasksHtml += `<li>${task.creator_name} assigned <b>${task.description}</b> to ${task.assignee_name} and the deadline is ${task.deadline} and the priority is ${task.priority}</li>`;
            }
            if (tasksHtml === "<ol>") {
              tasksHtml += "<li>No tasks created for this meeting</li>";
            }
            tasksHtml += "</ol>";
            formattedText += tasksHtml;
            this.setState({
              meetingMinutes: {
                ...res.data,
                text: RichTextEditor.createValueFromString(
                  formattedText,
                  "html"
                ),
                link: `https://docs.google.com/document/d/${res.data.file["id"]}/edit`,
              },
            });
          }
        } else {
          showAlert("error", res.status);
        }
      } catch (err) {
        showAlert(
          "error",
          "Some error occured, try again or contact developer"
        );
      }
    }
  };

  /*
    Opens edit todo modal with todo details
  */

  handleEditTodo = (todo) => {
    this.setState({
      todo: {
        title: todo.title,
        description: todo.description,
        assignee: todo.assignee_id,
        reporter: todo.reporter_id,
        priority: todo.priority,
        status: todo.status,
        meeting_id: todo.meeting_id,
        is_deleted: todo.is_deleted,
        project_vertical: todo.project_vertical,
        creator_id: todo.creator_id || todo.creator,
        deadline: moment(todo.deadline, "DD-MM-YYYY").format("YYYY-MM-DD"),
        id: todo.id,
        edited_history: todo.edited_history,
      },
      showModal: false,
      showTodoModal: true,
      isEditTodo: true,
    });
  };

  /*
    Edit the todo details using API
  */

  editTodo = async (e, is_deleted = false) => {
    const headers = {
      "auth-token": this.auth_token,
    };
    try {
      const {
        title,
        description,
        assignee,
        reporter,
        priority,
        deadline,
        status,
        meeting_id,
        project_vertical,
        id,
        creator_id,
        edited_history,
      } = this.state.todo;
      const isValid = this.validateTodo();
      if (!isValid) {
        return;
      }
      const data = {
        title,
        description,
        assignee_id: assignee,
        assignee_name: this.props.admins[assignee].split("_")[0],
        assignee_email: this.props.admins[assignee].split("_")[1],
        reporter_id: reporter,
        reporter_name: this.props.admins[reporter].split("_")[0],
        reporter_email: this.props.admins[reporter].split("_")[1],
        priority,
        deadline: moment(deadline, "YYYY-MM-DD").format("DD-MM-YYYY"),
        status,
        meeting_id,
        creator_id,
        creator_name: this.props.admins[creator_id].split("_")[0],
        creator_email: this.props.admins[creator_id].split("_")[1],
        editor_id: this.state.adminId,
        editor_name: this.props.admins[this.state.adminId].split("_")[0],
        editor_email: this.props.admins[this.state.adminId].split("_")[1],
        project_vertical,
        is_deleted,
        id,
        edited_history,
      };
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/edit_todo`,
        "PUT",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert(
          "success",
          `Successfully ${!is_deleted ? "updated" : "deleted"}`
        );
      } else {
        showAlert("error", res.status);
      }
      this.handleClose("showTodoModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
      this.handleClose("showTodoModal");
    }
  };

  /*
    Handles MOM changes in the editor
  */

  onChange = (value) => {
    this.setState({
      meetingMinutes: {
        ...this.state.meetingMinutes,
        text: value,
      },
    });
  };

  /*
    Validates the data and calls the API to save MOM
  */

  saveMeetingMinutes = async () => {
    const { title, text } = this.state.meetingMinutes;
    if (!title || text.toString("html") === "<p><br></p>") {
      showAlert("error", "Title and text required");
      return;
    }
    const headers = {
      "auth-token": this.auth_token,
    };
    const data = {
      title,
      text: text.toString("html"),
      meeting_id: this.state.meeting.id,
      creator_id: this.state.adminId,
      creator_name: this.props.admins[this.state.adminId].split("_")[0],
      creator_email: this.props.admins[this.state.adminId].split("_")[1],
    };
    try {
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/create_mm`,
        "POST",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.CREATED) {
        showAlert("success", "Successfully created");
        this.setState({
          meetingMinutes: {
            ...this.state.meetingMinutes,
            link: `https://docs.google.com/document/d/${res.data["id"]}/edit`,
            id: res.data["id"],
          },
        });
        //this.handleClose("showTodoModal");
        this.getMyMeetings();
      } else {
        this.handleClose("showTodoModal");
        showAlert("error", res.status);
      }
    } catch (err) {
      this.handleClose("showTodoModal");
      showAlert("error", "Some error occured, try again or contact developer");
    }
  };

  /*
    Validates the data and update MOM
  */

  updateMeetingMinutes = async () => {
    const {
      title,
      text,
      file: { id: file_id },
      id,
    } = this.state.meetingMinutes;
    if (!title || text.toString("html") === "<p><br></p>") {
      showAlert("error", "Title and text required");
      return;
    }
    const headers = {
      "auth-token": this.auth_token,
    };
    const data = {
      title,
      text: text.toString("html"),
      file_id,
      id,
    };
    try {
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/update_mm`,
        "POST",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully updated");
        this.handleClose("showModal");
      } else {
        showAlert("error", res.status);
      }
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
    }
  };

  /*
    Validates and delete MOM
  */

  deleteMeetingMinutes = async () => {
    try {
      if (!this.state.isSpecialAdmin) {
        showAlert(
          "error",
          "You are not authorized to do this action, contact console admin"
        );
        return;
      }
      const headers = {
        "auth-token": this.auth_token,
      };
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/mm/${this.state.meetingMinutes.file.id}`,
        "DELETE",
        null,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully deleted");
      } else {
        showAlert("error", res.status);
      }
      this.getMyMeetings();
      this.handleClose("showModal");
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
    }
  };

  /*
    Handles agenda input change
  */

  handleAgendaChange = (i, e) => {
    let formattedDescription = [...this.state.editedEvent.formattedDescription];
    let agenda = formattedDescription[i];
    agenda["val"] = e.target.value;
    formattedDescription[i] = agenda;
    this.setState({
      editedEvent: {
        ...this.state.editedEvent,
        formattedDescription,
      },
    });
  };

  /*
    Adds fields/points for agenda
  */

  handleAddAgenda = () => {
    this.setState({
      editedEvent: {
        ...this.state.editedEvent,
        formattedDescription: [
          ...this.state.editedEvent.formattedDescription,
          { val: "" },
        ],
      },
    });
  };

  /*
    removes fields/points for agenda
  */

  handleRemoveAgenda = (i) => {
    if (this.state.editedEvent.formattedDescription.length === 1) {
      return;
    }
    let formattedDescription = [...this.state.editedEvent.formattedDescription];
    formattedDescription.splice(i, 1);
    this.setState({
      editedEvent: {
        ...this.state.editedEvent,
        formattedDescription,
      },
    });
  };

  /*
    Shares MOM with all the attendees
  */

  handleShare = async (isUpdate = false) => {
    try {
      const data = {
        id: !isUpdate
          ? this.state.meetingMinutes.id
          : this.state.meetingMinutes.file.id,
        emails: this.state.meeting.attendees.map((attendee) => attendee.email),
        role: "reader",
      };
      const headers = {
        "auth-token": this.auth_token,
      };
      const res = await Network(
        `${API_URL_V2}/scheduler/weekly/share_mm`,
        "POST",
        data,
        headers
      );
      if (res.statusCode === STATUS_CODES.SUCCESS) {
        showAlert("success", "Successfully shared the file");
      } else {
        showAlert("error", res.status);
      }
    } catch (err) {
      showAlert("error", "Some error occured, try again or contact developer");
    }
  };

  /*
    Copies the MOM doc url to clipboard
  */

  copyDocURL = async () => {
    try {
      await navigator.clipboard.writeText(this.state.meetingMinutes.link);
      showAlert("success", "Copied to clipboard Successfully");
    } catch (err) {
      showAlert("error", err);
    }
  };

  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    const {
      meeting,
      isLoading,
      timezone,
      showModal,
      filterBy,
      isSpecialAdmin,
      showConfirmModal,
      events,
      email,
      showEditModal,
      editedEvent,
      showDeleteModal,
      deleteAll,
      adminId,
      todo,
      showTodoModal,
      tabKey,
      todos,
      isEditTodo,
      meetingMinutes,
      createTabKey,
      deleteEventMainId,
      deleteEventId,
    } = this.state;
    const { admins } = this.props;
    const today = moment().format("DD-MM-YYYY");
    meeting.attendees = meeting.attendees
      ? [...new Set(meeting.attendees)]
      : [];

    const defaultDate = new Date(
      momentTimeZone().tz(TIMEZONES[timezone]).format("YYYY-MM-DD")
    );
    const now = new Date(
      momentTimeZone()
        .tz(TIMEZONES[timezone])
        .format("ddd MMM DD YYYY HH:mm:ss")
    );
    const getNow = () => now;
    const scrollToTime = now;
    return (
      <>
        {isLoading ? (
          <HashLoader
            color={"#36D7B7"}
            loading={isLoading}
            css={override}
            size={60}
          />
        ) : (
          <div
            className="w-100 px-5 pb-4 h-100"
            ref={(el) => {
              this.el = el;
            }}
          >
            <strong className="text-center">
              Note: Meetings scheduled in previous,current and next month only
              available{" "}
            </strong>
            <div className="select-dropdown">
              <Form.Control
                as="select"
                value={filterBy}
                onChange={this.handleFilter}
              >
                <option value="1">My Meetings</option>
                <option value="4" disabled={!isSpecialAdmin}>
                  All Meetings
                </option>
              </Form.Control>
            </div>
            <div className="timezone-selector-new">
              <Form.Control
                as="select"
                name="timezone"
                value={timezone}
                onChange={this.handleTimeZoneSelector}
              >
                {Object.keys(TIMEZONES).map((timezone, i) => (
                  <option value={timezone} key={i}>
                    {timezone}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="calendar-view">
              <Calendar
                selectable
                localizer={localizer}
                events={events}
                defaultView={Views.MONTH}
                defaultDate={defaultDate}
                getNow={getNow}
                scrollToTime={scrollToTime}
                onSelectEvent={(event) => this.handleShowModal(event)}
                onSelectSlot={({ start, end }) =>
                  this.setState({ showConfirmModal: true, start, end })
                }
                dayLayoutAlgorithm="no-overlap"
                messages={{
                  agenda: "Summary",
                }}
                components={{
                  month: {
                    event: CustomEvent,
                  },
                }}
              />
            </div>
          </div>
        )}
        <Modal
          show={showModal}
          onHide={() => this.handleClose("showModal")}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {meeting.title}
              <Button
                variant="primary"
                className="ml-5"
                onClick={() => this.handleTodoModal(meeting.id)}
              >
                Create Task/Meeting minutes
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              fill
              justify
              activeKey={tabKey}
              onSelect={(k) => this.handleTabChange(k)}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <div>
                  <div className="card-body">
                    <div className="card-field">
                      <div className="card-field-header">Title : </div>
                      <div className="card-field-value">{meeting.title}</div>
                    </div>

                    <div className="card-field">
                      <div className="card-field-header">Agenda : </div>
                      <div className="card-field-value">
                        {(meeting.description || "Not specified").replace(
                          "Meeting Agenda:",
                          ""
                        )}
                      </div>
                    </div>
                    <div className="card-field">
                      <div className="card-field-header">Time : </div>
                      <div className="card-field-value">
                        {moment(meeting.start, "YYYY-MM-DD HH:mm").format(
                          "DD-MM-YYYY hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(meeting.end, "YYYY-MM-DD HH:mm").format(
                          "DD-MM-YYYY hh:mm A"
                        )}{" "}
                        <span className="font-weight-bold">{timezone}</span>
                      </div>
                    </div>
                    <div className="card-field">
                      <div className="card-field-header">
                        Total no of Attendees :{" "}
                      </div>
                      <div className="badge badge-pill badge-primary">
                        {meeting.attendees?.length || 0}
                      </div>
                    </div>
                    <ol className="list-group list-group-numbered">
                      {meeting.attendees?.map((attendee, i) => (
                        <li
                          className={`list-group-item ${
                            attendee.email === email ? "highlight-attendee" : ""
                          }`}
                          key={i}
                        >
                          <div>
                            <span className="font-weight-bold mr-3">
                              Email :
                            </span>
                            <span>{attendee.email}</span>
                          </div>
                        </li>
                      ))}
                    </ol>
                    <div className="card-field">
                      <div className="card-field-header">Invited by : </div>
                      <div className="card-field-value">
                        {meeting?.creator?.email || "Not available"}
                        {meeting?.creator?.email === email && (
                          <span className="font-weight-bold">(Me)</span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between flex-column mt-4">
                      <Button
                        variant="primary"
                        onClick={() =>
                          window.open(meeting.hangoutLink, "_blank")
                        }
                        className="mb-2"
                      >
                        Join with Google Meet
                      </Button>
                      {meeting?.conferenceData?.entryPoints
                        .filter(
                          (entrypoint) => entrypoint.entryPointType === "phone"
                        )
                        .map((entrypoint, i) => (
                          <Button
                            variant="primary"
                            onClick={() =>
                              window.open(entrypoint.uri, "_blank")
                            }
                            key={i}
                          >
                            {`Join by phone
                    (no:${entrypoint.label},pin:${entrypoint.pin})`}
                          </Button>
                        ))}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="todo" title="Tasks">
                {todos.length === 0 ? (
                  <div className="text-center">No tasks found</div>
                ) : (
                  <table
                    id="datatables-example"
                    className="table table-bordered table-responsive table-striped"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>Summary</th>
                        <th>Assignee</th>
                        <th>Reporter</th>
                        <th>Status</th>
                        <th>Deadline</th>
                        <th>Created</th>
                        <th>Updated</th>
                      </tr>
                    </thead>
                    <tbody id="tasks_table">
                      {todos.map((todo) => (
                        <tr
                          id={todo.id}
                          key={todo.id}
                          className={
                            `${moment(todo.deadline, "DD-MM-YYYY").diff(
                              moment(today, "DD-MM-YYYY"),
                              "hours"
                            )}` < 0
                              ? "critical-indi cursor-pointer"
                              : `${moment(todo.deadline, "DD-MM-YYYY").diff(
                                  moment(today, "DD-MM-YYYY"),
                                  "hours"
                                )}` < 23
                              ? "warning-indi cursor-pointer"
                              : "cursor-pointer"
                          }
                          onClick={() => this.handleEditTodo(todo)}
                        >
                          <td>
                            <div className="task-title">{todo.title}</div>
                          </td>
                          <td>{todo.assignee_name || todo.assignee_email}</td>
                          <td>{todo.reporter_name || todo.reporter_email}</td>
                          <td>{todoStatus[todo.status]}</td>
                          <td>
                            {todo.deadline} (
                            {moment(todo.deadline, "DD-MM-YYYY").diff(
                              moment(today, "DD-MM-YYYY"),
                              "days"
                            ) < 0
                              ? "Overdue"
                              : `${moment(todo.deadline, "DD-MM-YYYY").diff(
                                  moment(today, "DD-MM-YYYY"),
                                  "days"
                                )} days`}
                            )
                          </td>
                          <td>
                            {todo.created_at
                              ? moment(todo.created_at).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            {todo.last_updated
                              ? moment(todo.last_updated).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Tab>
              <Tab eventKey="mm" title="Meeting Minutes">
                {meetingMinutes.text && meetingMinutes.id ? (
                  <>
                    <div className="text-center">
                      <Form.Group controlId="formGroupName">
                        <Form.Control
                          type="text"
                          placeholder="Title..."
                          name="title"
                          value={meetingMinutes.title}
                          onChange={(e) =>
                            this.handleFieldChange(e, "meetingMinutes")
                          }
                        />
                      </Form.Group>
                      <RichTextEditor
                        value={meetingMinutes.text}
                        onChange={this.onChange}
                      />
                    </div>
                    {meetingMinutes.link && (
                      <div className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <a
                            href={meetingMinutes.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Doc link
                          </a>
                          <div
                            onClick={this.copyDocURL}
                            className="cursor-pointer"
                          >
                            <FaCopy />
                          </div>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => this.handleShare(true)}
                          className="cursor-pointer"
                        >
                          Share this file with the invitees
                        </Button>
                      </div>
                    )}
                    <div
                      className={`text-center mt-2 d-flex ${
                        isSpecialAdmin
                          ? "justify-content-between"
                          : "justify-content-center"
                      } align-items-center"`}
                    >
                      <Button
                        variant="primary"
                        onClick={this.updateMeetingMinutes}
                      >
                        Update
                      </Button>
                      {isSpecialAdmin && (
                        <Button
                          variant="danger"
                          onClick={this.deleteMeetingMinutes}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-center font-weight-bold">
                    No meeting minutes found
                  </div>
                )}
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100 justify-content-between">
              {((meeting.scheduler_id && meeting.scheduler_id === adminId) ||
                isSpecialAdmin) && (
                <>
                  <Button variant="primary" onClick={this.handleBtnEditClick}>
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() =>
                      this.handleDeleteModal(
                        meeting.recurringEventId,
                        meeting.id
                      )
                    }
                  >
                    Delete
                  </Button>
                </>
              )}
              <Button
                variant="secondary"
                onClick={() => this.handleClose("showModal")}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showEditModal}
          onHide={() => this.handleClose("showEditModal")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="card-body">
                <Form.Group controlId="formGroupName">
                  <Form.Label className="font-weight-bold">Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a Meeting Title"
                    name="summary"
                    value={editedEvent.summary}
                    onChange={(e) => this.handleFieldChange(e, "editedEvent")}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupName">
                  <Form.Label className="font-weight-bold">
                    Agenda{" "}
                    <MdAddCircle
                      className="cursor-pointer"
                      onClick={this.handleAddAgenda}
                    />
                  </Form.Label>
                  {editedEvent.formattedDescription.map((description, i) => (
                    <div
                      className="d-flex align-items-center cursor-pointer"
                      key={i}
                    >
                      <Form.Control
                        type="text"
                        placeholder={`Point ${i + 1}`}
                        name="description"
                        value={description.val}
                        onChange={(e) => this.handleAgendaChange(i, e)}
                      />
                      {i !== 0 && (
                        <MdOutlineRemoveCircle
                          onClick={() => this.handleRemoveAgenda(i)}
                        />
                      )}
                    </div>
                  ))}
                </Form.Group>
                <Form.Group controlId="formGroupStartTime">
                  <Form.Label className="d-flex justify-content-between align-items-center">
                    <span className="w-30 font-weight-bold">
                      Choose the attendees{" "}
                    </span>
                  </Form.Label>
                  <Select
                    isMulti={true}
                    isSearchable={true}
                    placeholder="Select or type an email and enter"
                    value={editedEvent.selectedAttendees}
                    onChange={this.handleChange}
                    options={editedEvent.eligibleAttendees}
                  />
                </Form.Group>
                <div className="card-field">
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">From</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      placeholder="Enter the starting time"
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      name="start"
                      value={editedEvent.start}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e) => this.handleFieldChange(e, "editedEvent")}
                    />
                  </Form.Group>
                </div>
                <div className="card-field">
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">To</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      placeholder="Enter the end time"
                      min={moment()
                        .add(30, "minutes")
                        .format("YYYY-MM-DDTHH:mm")}
                      name="end"
                      value={editedEvent.end}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e) => this.handleFieldChange(e, "editedEvent")}
                    />
                  </Form.Group>
                </div>
                {(editedEvent?.recurringEventId ||
                  editedEvent?.id?.includes("_")) && (
                  <Form.Group
                    controlId="formGroupName"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Form.Label className="font-weight-bold">
                      Change all meetings after this meeting?
                    </Form.Label>
                    <Form.Check
                      name="isAll"
                      type="checkbox"
                      onChange={this.handleCheckBox}
                      checked={editedEvent.isAll}
                      style={{ width: "5%" }}
                      id="owner-view-toggle"
                    />
                  </Form.Group>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100 justify-content-between">
              <Button variant="primary" onClick={this.handleEditConfirm}>
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => this.handleClose("showEditModal")}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showConfirmModal}
          onHide={() => this.handleClose("showConfirmModal")}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            No meeting scheduled at this time. Do you want to schedule a new
            one?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleSelect}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.handleClose("showConfirmModal")}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeleteModal}
          onHide={() => this.handleClose("showDeleteModal")}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to delete this meeting?</div>
            {(deleteEventMainId || deleteEventId.includes("_")) && (
              <Form.Group
                controlId="formGroupName"
                className="d-flex justify-content-between align-items-center"
              >
                <Form.Label className="font-weight-bold">
                  Delete all recurring meetings similar to this?
                </Form.Label>
                <Form.Check
                  name="deleteAll"
                  type="checkbox"
                  onChange={this.handleDeleteAll}
                  checked={deleteAll}
                  style={{ width: "5%" }}
                  id="owner-view-toggle"
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleDelete}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.handleClose("showDeleteModal")}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showTodoModal}
          onHide={() => this.handleClose("showTodoModal")}
        >
          <Modal.Header closeButton>Todo/Meeting Minutes</Modal.Header>
          <Modal.Body>
            <div className="mt-3">
              <Tabs
                activeKey={createTabKey}
                onSelect={(k) => this.handleCreateTabChange(k)}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="todo" title="Task">
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Task Title"
                      name="title"
                      value={todo.title}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Task Description..."
                      name="description"
                      value={todo.description}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">
                      Assignee
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="assignee"
                      value={todo.assignee || ""}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    >
                      <option value="" disabled={true}>
                        Select
                      </option>
                      {Object.keys(admins).map((id, i) => (
                        <option value={id} key={i}>
                          {admins[id]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">
                      Reporter
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="reporter"
                      value={todo.reporter || ""}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    >
                      {Object.keys(admins).map((id, i) => (
                        <option value={id} key={i}>
                          {admins[id]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formGroupStartTime">
                    <Form.Label className="font-weight-bold">
                      Vertical
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="project_vertical"
                      value={todo.project_vertical || ""}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    >
                      <option value="" disabled={true}>
                        Select
                      </option>
                      {this.props.teamDetails.map((team) => (
                        <option key={team.short_name} value={team.short_name}>
                          {team.short_name}
                        </option>
                      ))}
                      <option value="others">OTHERS</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">
                      Priority
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="priority"
                      value={todo.priority || ""}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    >
                      <option value="highest">Highest</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                      <option value="lowest">Lowest</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">
                      Deadline
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Task deadline"
                      name="deadline"
                      min={moment().format("YYYY-MM-DD")}
                      value={todo.deadline}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupName">
                    <Form.Label className="font-weight-bold">Status</Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      value={todo.status}
                      onChange={(e) => this.handleFieldChange(e, "todo")}
                    >
                      <option value="todo">Todo</option>
                      <option value="progress">In Progress</option>
                      <option value="review">To Be Reviewed</option>
                      <option value="done">Done</option>
                    </Form.Control>
                  </Form.Group>
                  <div
                    className={`text-center mt-2 d-flex ${
                      isEditTodo && isSpecialAdmin
                        ? "justify-content-around"
                        : "justify-content-center"
                    } align-items-center"`}
                  >
                    <Button
                      variant="primary"
                      onClick={isEditTodo ? this.editTodo : this.createTodo}
                    >
                      {isEditTodo ? "Update" : "Create"}
                    </Button>
                    {isEditTodo && isSpecialAdmin && (
                      <Button
                        variant="danger"
                        onClick={(e) => this.editTodo(e, true)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="mm" title="Meeting Minutes">
                  {!meeting.is_meeting_minutes ? (
                    moment(meeting.start, "YYYY-MM-DD HH:mm").diff(
                      moment(),
                      "minutes"
                    ) < 0 ? (
                      <div>
                        <div className="text-center">
                          <Form.Group controlId="formGroupName">
                            <Form.Control
                              type="text"
                              placeholder="Title..."
                              name="title"
                              value={meetingMinutes.title}
                              onChange={(e) =>
                                this.handleFieldChange(e, "meetingMinutes")
                              }
                            />
                          </Form.Group>
                          <RichTextEditor
                            value={meetingMinutes.text}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <Button
                            variant="primary"
                            onClick={this.saveMeetingMinutes}
                            disabled={!!meetingMinutes.link}
                          >
                            Save
                          </Button>
                        </div>
                        {meetingMinutes.link && (
                          <div className="text-center">
                            <div className="d-flex justify-content-center align-items-center">
                              <a
                                href={meetingMinutes.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Doc link
                              </a>
                              <div
                                onClick={this.copyDocURL}
                                className="cursor-pointer"
                              >
                                <FaCopy />
                              </div>
                            </div>
                            <Button
                              variant="primary"
                              onClick={this.handleShare}
                              className="cursor-pointer"
                            >
                              Share this file with the invitees
                            </Button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="font-weight-bold">
                        This meeting has not started yet, you can create once
                        the meeting starts
                      </div>
                    )
                  ) : (
                    <div className="text-center font-weight-bold">
                      Meeting minutes already created for this meeting, you can
                      update it in Meeting Minutes tab
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleClose("showTodoModal")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MyMeetings;
